import React from "react";
import Logo from "./img_siden_logo.png";
import MiniLogo from "./img_siden_logo_mini.png";
import NewLogoSquare from "./new_logo_square.jpg";
import "./App.css";

import { Layout, Menu } from "antd";
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  WifiOutlined,
  UserOutlined,
  UploadOutlined,
  VideoCameraOutlined,
  LineChartOutlined,
  BugOutlined,
  SettingOutlined,
  HomeOutlined,
  AndroidOutlined,
} from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";

import SidenLogo  from "./sidenLogo.svg"
import SidenSymbol  from "./sidenSymbol.svg"

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

function SideMenu(props) {
  let history = useHistory();
  let location = useLocation();

  const sidenEmployee =
    (props.userGroups || []).includes("siden_employee_group") || false;

  const contentProviderGroup =
    (props.userGroups || []).includes("content_provider_group") || false;

  const qATestingGroup =
    (props.userGroups || []).includes("qa_testing_group") || false;

  
  if (contentProviderGroup) {
     return (
  <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[location && location.pathname.substring(1)]}
      onClick={({ item, key, keyPath, domEvent }) => {
        console.log({ item, key, keyPath, domEvent });
        history.push(`/${key}`);
      }}
    >
      { props.collapsed ? <span className="submenu-title-wrapper">
        <img className="faviconLogo" src={SidenSymbol} alt="Siden Logo" />
      </span> : <span className="submenu-title-wrapper">
        <img className="logo" src={SidenLogo} alt="Siden Logo" />
      </span>}
      {/* <Menu.Item key="home" icon={<HomeOutlined />}>
        Home
      </Menu.Item> */}
      <SubMenu key="content" icon={<VideoCameraOutlined />} title="Content">
        <Menu.Item key="content_engagement" icon={<LineChartOutlined />}>
          Engagement Dashboard
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
  }

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[location && location.pathname.substring(1)]}
      onClick={({ item, key, keyPath, domEvent }) => {
        console.log({ item, key, keyPath, domEvent });
        history.push(`/${key}`);
      }}
    >
      { props.collapsed ? <span className="submenu-title-wrapper">
        <img className="faviconLogo" src={SidenSymbol} alt="Siden Logo" />
      </span> : <span className="submenu-title-wrapper">
        <img className="logo" src={SidenLogo} alt="Siden Logo" />
      </span>}
      <Menu.Item key="home" icon={<HomeOutlined />}>
        Home
      </Menu.Item>
      <SubMenu key="service" title="Service"  icon={<CloudOutlined/>}>
        <Menu.Item key="service_operation">Content Delivery</Menu.Item>
        <SubMenu
          key="service_management"
          icon={<SettingOutlined />}
          title="Management"
        >
          <Menu.Item key="service_controls">Service Controls</Menu.Item>
          <Menu.Item key="service_dorm_cap">
            Dormant Capacity Controls
          </Menu.Item>
        </SubMenu>
      </SubMenu>
      <SubMenu key="network" icon={<WifiOutlined/>} title="Network">
        <Menu.Item key="network_content_delivery">Utilization</Menu.Item>
        <SubMenu key="network_cells" title="Cells">
          <Menu.Item key="network_cells_cells_usage">Cell Usage</Menu.Item>
          <Menu.Item key="network_cells_cells_summary">Cells Summary</Menu.Item>
          <Menu.Item key="cell_management">Cell Management</Menu.Item>
          <Menu.Item key="network_cells_service_prb_reports">
            PRB Reports
          </Menu.Item>
        </SubMenu>
        {/* <Menu.Item key="network_cells_service_provisioning">
          Service Provisioning
        </Menu.Item> */}
      </SubMenu>
     {sidenEmployee &&  <Menu.Item key="subscribers" icon={<TeamOutlined />}>
        Subscribers
      </Menu.Item>}
      <SubMenu key="devices" icon={<AndroidOutlined />} title="Devices">
        <Menu.Item key="devices_dashboard">Devices Dashboard</Menu.Item>
        <Menu.Item key="device_status">Device Status</Menu.Item>
      </SubMenu>
      {/* <SubMenu key="content" icon={<VideoCameraOutlined />} title="Content">
        <Menu.Item key="content_engagement" icon={<LineChartOutlined />}>
          Engagement Dashboard
        </Menu.Item>
      </SubMenu> */}
      {sidenEmployee && <SubMenu key="Debug" icon={<BugOutlined />} title="Debug">
        <Menu.Item key="debug_service_analytics" icon={<BarChartOutlined />}>
          Service Analytics
        </Menu.Item>
        <Menu.Item key="debug_virtual_cells">Virtual Cell Management</Menu.Item>
        {/* <Menu.Item key="debug_subscribers">Virtual Subscribers</Menu.Item> */}
        <Menu.Item key="debug_devices">Virtual Devices</Menu.Item>
        {/* <Menu.Item key="hub_configurations">Hub Config</Menu.Item> */}
        <Menu.Item key="dashboard_status">Is it working?</Menu.Item>
        <Menu.Item key="encrypted_cells">Encrypted Cells</Menu.Item>
        <Menu.Item key="super_admin_panel">Super Admin</Menu.Item>
      </SubMenu>}
      {qATestingGroup && <SubMenu key="QADebug" icon={<BugOutlined />} title="QA ONLY Debug">
        <Menu.Item key="dashboard_status">Is it working?</Menu.Item>
      </SubMenu>}
      {/* <Menu.Item key="user" icon={<UserOutlined />}>
        nav 4
      </Menu.Item>
      <Menu.Item key="5" icon={<CloudOutlined />}>
        nav 5
      </Menu.Item>
      <Menu.Item key="6" icon={<AppstoreOutlined />}>
        nav 6
      </Menu.Item>
      <Menu.Item key="7" icon={<TeamOutlined />}>
        nav 7
      </Menu.Item>
      <Menu.Item key="8" icon={<ShopOutlined />}>
        nav 8
      </Menu.Item>
         */}
    </Menu>
  );
}

export default SideMenu;
