import { BASE_URL, API_URL } from "../constants.js";
import { Auth } from "aws-amplify";
import { generateAuthHeader } from "../authHeader";
import { notification, message } from "antd";
import { requestGet, requestPost } from "../newApiCallsHelper.js";

const axios = require("axios");

export const getGlobalData = (setGlobalData, setIsLoaded) => {
  return requestGet(`${API_URL}/api/v1/dc/globalData`)
    .then(
      (result) => {
        console.log(result);
        setGlobalData(result.data);

        setIsLoaded(true);
      },
      (error) => {
        console.log(error);
      }
    );
};

export const updateGlobalData = (data) => {
  return requestPost(`${API_URL}/api/v1/dc/globalData`, data)
    .then((response) => {
      console.log(response);
      message.success("Updated");
    })
    .catch(function (error) {
      // handle error
      notification["error"]({
        message: "Error on Update",
        description: "Something went wrong",
      });
      console.log(error);
    });
};
