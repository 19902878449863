import axios from "axios"
import { Auth } from "aws-amplify";
 
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import gql from "graphql-tag";
import { notification } from "antd";

const apiGatewayUrl = "https://96opyzh9hg.execute-api.us-east-2.amazonaws.com/QSTest?"

const GET_QUICK_SIGHT_URL = gql`
  mutation getQuickSightURL(		$dashboardId: String!,
		$dashboardRegion: String!,
		$openIdToken: String!,
		$resetDisabled: Boolean!,
        $undoRedoDisabled: Boolean!
        ) {
 	  getQuickSightURL(dashboardId: $dashboardId,
		dashboardRegion: $dashboardRegion,
		openIdToken: $openIdToken,
		resetDisabled: $resetDisabled,
		undoRedoDisabled: $undoRedoDisabled) {
        errorMessage
        url
        EmbedUrl
        embedUrl
    }
  }
`;

export const asyncRunQueryRaw = async (queryParams) => {
    const runQuery = await API.graphql(graphqlOperation(GET_QUICK_SIGHT_URL, queryParams));
    return runQuery
}

export const asyncGetQSUrl = async (queryParams, resultSetter) => {
    const runQuery = await API.graphql(graphqlOperation(GET_QUICK_SIGHT_URL, queryParams));

    console.log({runQuery})

    if (runQuery.data.getQuickSightURL.errorMessage) {
        notification['error']({
          message: 'Error',
          description: runQuery.data.getQuickSightURL.errorMessage,
        });
        console.log({queryErrors: runQuery.data.getQuickSightURL.errorMessage})
    }
   resultSetter(runQuery.data.getQuickSightURL.EmbedUrl)
   return runQuery.data.getQuickSightURL.EmbedUrl
  }

//Make APIGateway call to generate the embed url.
export const apiGatewayGetDashboardEmbedUrl = (
    apiGatewayUrl,
    dashboardId,
    dashboardRegion,
    openIdToken,
    resetDisabled,
    undoRedoDisabled
) => {
    const parameters = {
    dashboardId: dashboardId,
    dashboardRegion: dashboardRegion,
    openIdToken: openIdToken,
    resetDisabled: resetDisabled,
    undoRedoDisabled: undoRedoDisabled,
    };

    var queryString = Object.keys(parameters).map(key => key + '=' + parameters[key]).join('&');
    // const myQueryString = $.param(parameters);
    apiGatewayUrl = apiGatewayUrl + queryString;
    // console.log({apiGatewayUrl})
    // return;

    const headers = { "Content-Type": "application/json" };

    return axios
    .get(apiGatewayUrl, { headers: headers })
    .then((response) => {
        return response.data.EmbedUrl;
    })
    .catch(function (error) {
        // writeDebugInfo(error);
        console.log("Error obtaining QuickSight dashboard embed url.");
    });
}