import { BASE_URL, API_URL } from "../constants.js";
import { notification, message } from "antd";

import {
  requestForwarder,
  requestForwarderPost,
  requestGet,
  requestPost,
} from "../newApiCallsHelper";

const axios = require("axios");

export const getCPEMappingByHubId = (hubId) => {
  return requestGet(`${BASE_URL}/grpc/v1/scgw/cpe/mapping?hubId=${hubId}`).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getTopologyMappingByHubId = (hubId) => {
  return requestGet(`${API_URL}/grpc/v1/networktopology/hub/cell/${hubId}`)
}


export const getCPEMappingByCPE = (imsi) => {
  return requestGet(`${BASE_URL}/grpc/v1/scgw/cpe/mapping?imsi=${imsi}`).then(
    (result) => {
      return result;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const createAccountNew = (
  newAccountInfo,
  onAccountCreationSuccess,
  onAccountCreationFailure
) => {
  return requestPost(
    `${API_URL}/grpc/v1/accounts/hubs?limit=1000`,
    newAccountInfo
  ).then(
    (result) => {
      console.log({ result });
      const { sidenAccountId } = result.data;

      notification["success"]({
        message: "New Account Created",
        description: `New Account Created with Siden Account ID ${sidenAccountId}`,
      });
      console.log(result);
      onAccountCreationSuccess(sidenAccountId);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Creation",
        description: error.response.data.message,
      });
      onAccountCreationFailure();
    }
  );
};

export const changeAccountStatus = (desiredAction, mnoAccountId) => {
  return requestPost(`${BASE_URL}/grpc/v1/scgw/account/${desiredAction}`, {
    mnoAccountId,
  }).then(
    (result) => {
      console.log({ result });
      message.success(`Account ${desiredAction}d`);
      console.log(result);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Status Change",
        description: error.response.data.message,
      });
    }
  );
};
export const updateHubLocation = (mnoAccountId, hubId, imsi) => {
  return requestPost(`${BASE_URL}/grpc/v1/scgw/account/location`, {
    mnoAccountId,
    hubId,
    imsi,
  }).then(
    (result) => {
      console.log({ result });
      message.success(`Hub Updated`);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Hub Location Update",
        description: error.response.data.message,
      });
    }
  );
};

export const getTLTEThreshold = (cellId, setTLTEThreshold) => {
  return requestGet(`${API_URL}/api/v1/dc/tlteThreshold/${cellId}`).then(
    (result) => {
      setTLTEThreshold(result.data)
      return result.data;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getRLTEThresholdUpper = (cellId, setRLTEThresholdUpper) => {
  return requestGet(`${API_URL}/api/v1/dc/rlteThresholdUpper/${cellId}`).then(
    (result) => {
      setRLTEThresholdUpper(result.data)
      return result.data;
    },
    (error) => {
      console.log(error);
    }
  );
};
export const getRLTEThresholdLower = (cellId, setRLTEThresholdLower) => {
  return requestGet(`${API_URL}/api/v1/dc/rlteThresholdLower/${cellId}`).then(
    (result) => {
      setRLTEThresholdLower(result.data)
      return result.data;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getRTLEPolicy = (cellId, setRTLEPolicy) => {
  return requestGet(`${API_URL}/api/v1/dc/rlte/policy/${cellId}`).then(
    (result) => {
      setRTLEPolicy(result.data)
      return result.data;
    },
    (error) => {
      console.log(error);
    }
  );
};
export const getPRBInterval = (cellId, setPRBInterval) => {
  return requestGet(`${API_URL}/api/v1/dc/prbinterval/${cellId}`).then(
    (result) => {
      setPRBInterval(result.data)
      return result.data;
    },
    (error) => {
      console.log(error);
    }
  );
};

export const updateTLTEThreshold = (cellId, tltethreshold) => {
  return requestPost(`${API_URL}/api/v1/dc/tlteThreshold`,
    {cellId, tltethreshold}
  ).then(
    (result) => {
      console.log({result})
      notification['success']({
          message: 'TLTE Threshold Updated',
          description: 'Success',
        });
    },
    (error) => {
        notification['error']({
          message: 'TLTE Threshold Upate Failed',
          description: 'Error',
        });
      console.log(error);
    }
  );
};

export const updateRLTEThresholdUpper = (cellId, rltethresholdupper) => {
  return requestPost(`${API_URL}/api/v1/dc/rlteThresholdUpper`, {rltethresholdupper, cellId}).then(
    (result) => {
      console.log({result})
      notification['success']({
          message: 'RLTE Threshold Upper Updated',
          description: 'Success',
        });
    },
    (error) => {
        notification['error']({
          message: 'RLTE Threshold Lower Upper',
          description: 'Error',
        });
      console.log(error);
    }
  );
};
export const updateRLTEThresholdLower = (cellId, rltethresholdlower) => {
  return requestPost(`${API_URL}/api/v1/dc/rlteThresholdLower`,{rltethresholdlower, cellId}).then(
    (result) => {
      console.log({result})
      notification['success']({
          message: 'RLTE Threshold Lower Updated',
          description: 'Success',
        });
        return result
    },
    (error) => {
        notification['error']({
          message: 'RLTE Threshold Lower Error',
          description: 'Error',
        });
      console.log(error);
    }
  );
};

// old UE
// : {id: "fakeImsi4", cellId: "00101012d687", IMSI: "fakeImsi4", IMEISV: "", APN: "", IP: "192.168.1.104"}
// APN: ""
// IMEISV: ""
// IMSI: "fakeImsi4"
// IP: "192.168.1.104"
// cellId: "00101012d687"
// id: "fakeImsi4"
// 5: {id: "fakeImsi5", cellId: "00101012d687", IMSI: "fakeImsi5", IMEISV: "", APN: "", IP: "192.168.1.105"}
// 6: {id: "V-AV-001", cellId: "9900000000700", IMSI: "V-AV-001", IMEISV: "", APN: "",…}
// 7: {id: "V-AK-001", cellId: "9900000000700", IMSI: "V-AK-001", IMEISV: "", APN: "",…}
// APN: ""
// IMEISV: ""
// IMSI: "V-AK-001"
// IP: "V-AK-001-1592814559886"
// cellId: "9900000000700"
// id: "V-AK-001"

// GET CPE MAPPING

// attachmentState: 'Attached';
// cellId: '00101012d687';
// cpeIp: '192.168.1.102';
// hubId: 'WH2206C87351B187';
// imsi: 'fakeImsi2';
// virtual: true;

// 24:
// attachmentState: "Detached"
// cellId: "00101012d687"
// cpeIp: "192.168.2.10"
// hubId: ""
// imsi: "3578930800010800"
// virtual: false
// __proto__: Object
// 25:
// attachmentState: "Attached"
// cellId: "9900000000700"
// cpeIp: "192.44.44.44"
// hubId: "WH220485A45DF0AF"
// imsi: "fakeOleksiiIMSI"
// virtual: true
