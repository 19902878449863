import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio, Select } from 'antd';
import { REACT_APP_QS_PRB_REPORT } from '../constants';

const {Option} = Select

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface AssignSubscriptionProps {
  visible: boolean;
  onEdit: (values: Values) => void;
  onCancel: () => void;
}

const AssignSubscription: React.FC<AssignSubscriptionProps> = ({
  visible,
  onEdit,
  onCancel,
  account,
  debugMode,
  catalogDetails
}) => {
  const [form] = Form.useForm();

  const bundlesInCatalog = catalogDetails

  console.log({account})
  return (
    <Modal
      visible={visible}
      title="Assign Subscription Manually"
      okText="Assign Subscription"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onEdit(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          sidenAccountId: account.sidenAccountId
        }}
      >
        <Form.Item 
          name="sidenAccountId" 
          label="Siden Account Id" 
          hidden
          rules={[
            {
              // required: true
              message: 'Please input the sidenAccountId',
            },
        ]}>
            <Input />
        </Form.Item>
        <Form.Item 
            name="productPlanId" 
            label="Product Plan ID" 
            rules={[
                  {
                    required: true,
                    message: 'Please input the Hub ID',
                  },
                ]}>
                <Select>
                  {bundlesInCatalog.map(bundle=> 
                  <Option value={bundle["code"]}>{`${bundle["code"]}-${bundle["name"]}`}</Option>)}
                </Select>
            </Form.Item>
      </Form>
    </Modal>
  );
};

export default AssignSubscription;