import React from 'react';

import Iframe from '../Shared/Iframe';
import { CLOUD_MANAGEMENT_GRAFANA } from '../constants';

const cloudManagmentIframe = `<iframe _ngcontent-c6="" class="iframe-grafana ng-star-inserted" src="${CLOUD_MANAGEMENT_GRAFANA}?orgId=1&amp;from=now-12h&amp;refresh=5s" style="position: absolute;height: calc(100% - 65px);width: 100%;"></iframe>`;

const CloudManagment = () => <Iframe iframe={cloudManagmentIframe} />;

export default CloudManagment;
