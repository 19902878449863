import Amplify, { API, graphqlOperation } from 'aws-amplify';
import gql from "graphql-tag";

import { Row, Button, message, notification, Result, Descriptions, Badge, Col, Divider, Progress, Table, Typography, Collapse } from "antd";

const GET_REDSHIFT_RESULT = gql`
  query getRedshiftById($id: String!) {
    getRedShiftResult(id: $id) {
      error {
        code
        message
        requestId
        retryDelay
        retryable
        statusCode
        time
      }
      totalNumRows
      records {
        doubleValue
        longValue
        stringValue
      }
      columnMetadata {
        isCaseSensitive
        isCurrency
        label
        scale
        length
        name
      }
    }
  }
`;
const REDSHIFT_DESCRIBE_STATEMENT = gql`
  query redshiftDescribeStatement($id: String!) {
    redshiftDescribeStatement(id: $id) {
      id
      clusterIdentifier
      createdAt
      duration
      error {
        code
        message
        requestId
        retryDelay
        retryable
        statusCode
        time
      }
      queryString
      redshiftPid
      redshiftQueryId
      resultRows
      resultSize
      updatedAt
      status
    }
  }
`;

const EXECUTE_REDSHIFT_QUERY = gql`
  mutation ExecuteRedshiftQuery($queryIdentifier: String!, $param: String) {
 	  executeRedshiftQuery(queryIdentifier: $queryIdentifier, param: $param) {
      clusterIdentifier
      createdAt
      database
      dbUser
      id
      error
    }
  }
`;

  function sleep(ms) {
    return new Promise(
      resolve => setTimeout(resolve, ms)
    );
  }

  const describeStatement = async (id)=>{
    const result = await API.graphql(graphqlOperation(REDSHIFT_DESCRIBE_STATEMENT, {id: id}));
    if (result.data.redshiftDescribeStatement.status != "FINISHED") {
      await sleep(1000)
      return await describeStatement(id)
    } else
    return result.data.redshiftDescribeStatement;
  }

export const asyncRunQuery = async (queryParams, resultSetter) => {
    const runQuery = await API.graphql(graphqlOperation(EXECUTE_REDSHIFT_QUERY, queryParams));

    if (runQuery.data.executeRedshiftQuery.error) {
        notification['error']({
          message: 'Error',
          description: runQuery.data.executeRedshiftQuery.error,
        });
        console.log({queryErrors: runQuery.data.executeRedshiftQuery.error})
    }
    const describe = await describeStatement(runQuery.data.executeRedshiftQuery.id)

      if (describe.error) {
        notification['error']({
          message: 'Error',
          description: describe.error,
        });
        console.log({describeErrors: describe.error})
    } 
    const result = await  API.graphql(graphqlOperation(GET_REDSHIFT_RESULT, {id: describe.id}));
        resultSetter(result.data.getRedShiftResult);
  }

//  useEffect(getHubErrors, []);

//  console.log({hubErrorsQuery})