import React, { useState } from 'react';
import { Input, Row, message, notification, Col } from 'antd';
import { getCPEByType, searchForHub } from './apiCalls.js';
import { getTopologyMappingByHubId, getCPEMappingByCPE } from './newApiCalls.js';

const { Search } = Input;

const CellSearchBar = props => {
  const { setFoundCell } = props;
  const [hubId, setHubId] = useState('');
  const [CPE, setCPE] = useState('');

  const cellSearchByCPE = () => {
    if (!CPE) return;

    getCPEMappingByCPE(CPE).then(response => {
      console.log({ response });
      if (response.data.data.length == 1) {
        notification['success']({
          message: 'Cell ID Found',
          description: response.data.data[0].cellId,
        });
        setFoundCell(response.data.data[0].cellId);
        setCPE('');
      }
      if (response.data.data.length == 0) {
        notification['success']({
          message: 'CPE Not Found',
          description: 'Search succeeded, but CPE not found',
        });
        setCPE('');
      }
    });
    // .catch(error => {
    //   notification['error']({
    //     message: 'Error on CPE Lookup',
    //     description: 'Something went wrong',
    //   });
    //   return error;
    // });
  };

  const cellSearchByHubId = () => {
    if (!hubId) return;

    getTopologyMappingByHubId(hubId).then(
      (result) => {
        console.log({ result });
        notification['success']({
          message: 'Cell ID Found',
          description: result.data.cellId,
        });
        setFoundCell(result.data.cellId);
        setHubId('');
        return result
      },
      (error) => {
        console.log(error);
        notification['error']({
        message: 'Error on Hub Lookup',
        description: 'Something went wrong',
      });
      }
    );
    
    // .then(response => {
    //   console.log({ response });
    //   if (response.data.data.length == 1) {
    //     notification['success']({
    //       message: 'Cell ID Found',
    //       description: response.data.data[0].cellId,
    //     });
    //     setFoundCell(response.data.data[0].cellId);
    //     setHubId('');
    //   }
    //   if (response.data.data.length == 0) {
    //     notification['success']({
    //       message: 'Hub Not Found',
    //       description: 'Search succeeded, but Hub not found',
    //     });
    //     setHubId('');
    //   }
    // });
    // .catch(error => {
    //   notification['error']({
    //     message: 'Error on Hub Lookup',
    //     description: 'Something went wrong',
    //   });
    //   return error;
    // });
  };

  return (
    <Col>
      <Row style={{ marginBottom: '10px' }} justify="space-between">
        <div style={{ maxWidth: '800px' }}>
          <Search
            label="Hub ID"
            placeholder="Search by Hub ID"
            onChange={e => setHubId(e.target.value)}
            value={hubId}
            onSearch={cellSearchByHubId}
            enterButton
          />
        </div>
        &nbsp; &nbsp; &nbsp;
        {/* <div style={{ maxWidth: '800px' }}>
          <Search
            label="CPE"
            placeholder="Search by CPE"
            onChange={e => setCPE(e.target.value)}
            onSearch={cellSearchByCPE}
            value={CPE}
            enterButton
          />
        </div> */}
      </Row>
    </Col>
  );
};

export default CellSearchBar;
