// React
import React, { useEffect, useState } from "react";

import { generateAuthHeader, generateSidenMNOToken, parseJWT, generateSidenToken } from "../authHeader";

import { Auth } from "aws-amplify";
import { PlusOutlined } from "@ant-design/icons";

import { Row, Button, message, notification, Result , Descriptions, Spin, Popconfirm, Table, Col, Collapse, Tag} from "antd";

import { Link } from "react-router-dom";

import moment from "moment"

import { loadAllSCEConfigs, loadAllUIConfigs, loadDiskConfig} from "./newApiCalls.js";
import { useParams } from "react-router";
import Title from "antd/lib/typography/Title";

import ReactJson from 'react-json-view'
import { displayedVersion, REACT_APP_QS_HOME_PAGE } from "../constants";
import { asyncRunQueryRaw } from "../Shared/quickSight";

// use the component in your app!
const {Panel} = Collapse;


const axios = require("axios");

const checkVersion = () => {
    return Auth.currentAuthenticatedUser().then((user) => {
      return axios
        .get(
          `${window.location.origin}/version_number.txt`, generateAuthHeader(user)
        )
        .then((response) => {
          const serverVersion = response.data;
          return serverVersion
        })
        .catch(() => ("Version Check Error"));
    });
  };

const yesNoTag = (boolean) => (
  boolean ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag> 
)

const DashboardConfigs = (props) => {
  const [DashboardConfigs, setDashboardConfigs] = useState([])
  const [sidenToken, setSidenToken] = useState({})
  const [sidenMNOToken, setSidenMNOToken] = useState({})
  const [serverVersion, setServerVersion] = useState("Unavailable")
  const [rawQSData, setRawQSData] = useState(null)

  const {user} = props;
  const userGroups = user ? user.signInUserSession.idToken.payload["cognito:groups"] : []

  // const [user, setUser] = useState({})
  // const [activePurchase, setActivePurchase] = useState([])
  // const [historyPurchases, setHistoryPurchases] = useState([])
  // const [catalog, setCatalog] = useState({});

// const {sidenAccountId} = useParams()

  // useEffect(() => {
    // loadAccountBySID(setAccount, sidenAccountId).then(account=>{
    //   if(account) {
    //     loadHubUser(setUser, account.hubId)
    //   }
  //   })
  // }, [])

    useEffect(() => {
      generateSidenToken().then(token=> setSidenToken(token))
    }, [])
    useEffect(() => {
      generateSidenMNOToken().then(token=> setSidenMNOToken(token))
    }, [])
    useEffect(() => {
      // checkVersion().then(serverVersion=> setServerVersion(serverVersion))
    }, [])
    useEffect(() => {
      const parameters = {
        dashboardId: REACT_APP_QS_HOME_PAGE,
        dashboardRegion:  "us-east-2",
        openIdToken: user.signInUserSession.idToken.jwtToken,
        resetDisabled: true,
        undoRedoDisabled: true,
    }

      asyncRunQueryRaw(parameters).then(raw=> setRawQSData(raw))
    }, [])
  // const columns = [
  //   {
  //     key: "plan",
  //     title: "Plan",
  //     dataIndex: "plan",
  //   },
  //   {
  //     key: "name",
  //     title: "name",
  //     dataIndex: "plan",
  //     render: (datum) => catalog[datum],
  //   },
  //   {
  //     key: "isPromo",
  //     title: "isPromo?",
  //     dataIndex: "isPromo",
  //     render: (datum) => String(datum),
  //   },
  //   {
  //     key: "start",
  //     title: "Start",
  //     dataIndex: "start",
  //   },
  //   {
  //     key: "end",
  //     title: "End",
  //     dataIndex: "end",
  //   },
  // ]

  const parsedSidenToken = parseJWT(sidenToken.jwt)
  const parsedSidenMNOToken = parseJWT(sidenMNOToken.jwt)

  return (
    <Col>
       <Title level={2}>Dashboard Configurations</Title>
        <Row>
          {/* <Button type="primary" onClick={forceRefresh}>Refresh</Button> */}
        </Row>
        <Row>
          <Title level={5}>Use this page for troubleshooting common issues and permission checking</Title>
        </Row>
         <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>Is a hard refresh necessary?</Title>
          </Row>
          <Row>
            <Row align="center">
              <Descriptions
                bordered
                column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
                >
              <Descriptions.Item label="Dashboard Version">
                {displayedVersion}
              </Descriptions.Item>
              <Descriptions.Item label="Server Code Version">
                {serverVersion}
              </Descriptions.Item>
              <Descriptions.Item label="Up to Date?">
                {serverVersion == displayedVersion ? <Tag color="green">Version Match</Tag>
                :  <Tag color="red">Please Hard Refresh</Tag>  
              }
              </Descriptions.Item>
            </Descriptions>
            </Row>
          </Row>
        </Col>
         <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>MNO ID/Role Permissions</Title>
          </Row>
            <Row>
              <Descriptions
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}
                >
              <Descriptions.Item label="Siden Token Role">
                {parsedSidenToken["https://siden.io/role"]}
              </Descriptions.Item>
              <Descriptions.Item label="Siden Token MNO ID">
                 {parsedSidenToken["https://siden.io/mnoId"]}
              </Descriptions.Item>
            </Descriptions>
          </Row>
            <Row>
              <Title level={5}>Siden and MNO Token info should match Unless You Have "admin" role in Siden Token</Title>
          </Row>
            <Row>
              <Descriptions
                bordered
                column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}
                >
              <Descriptions.Item label="MNO Token Role">
                 {parsedSidenMNOToken["https://siden.io/role"]}
              </Descriptions.Item>
              <Descriptions.Item label="MNO Token MNO ID">
                {parsedSidenMNOToken["https://siden.io/mnoId"]}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Col>
         <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>Group Permissions</Title>
          </Row>
            <Row>
              <Descriptions
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
              <Descriptions.Item label="Are you a siden employee?">
                {yesNoTag(userGroups.includes("siden_employee_group"))}
              </Descriptions.Item>
              <Descriptions.Item label="Are you a siden service admin?">
                {yesNoTag(userGroups.includes("siden_service_admin"))}
              </Descriptions.Item>
              <Descriptions.Item label="Are you a xl_user?">
                {yesNoTag(userGroups.includes("xl_user"))}
              </Descriptions.Item>
              <Descriptions.Item label="Are you in the LM MNO group?">
                {yesNoTag(userGroups.includes("mno_m17641935"))}
              </Descriptions.Item>
              <Descriptions.Item label="Are you in the XL MNO group?">
                {yesNoTag(userGroups.includes("mno_m17641936"))}
              </Descriptions.Item>
              <Descriptions.Item label="Are you in the m17641937 MNO group?">
                {yesNoTag(userGroups.includes("m17641937"))}
              </Descriptions.Item>
              <Descriptions.Item label="All User Groups">
                { userGroups.map((userGroup) => <li>{userGroup}</li>)}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Col>
         <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>Who Am I?</Title>
          </Row>
            <Row>
             {user && <Descriptions
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
              <Descriptions.Item label="Email">
                {user.signInUserSession.idToken.payload.email}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {user.signInUserSession.idToken.payload.name}
              </Descriptions.Item>
              <Descriptions.Item label="Cognito Username">
                {user.signInUserSession.idToken.payload["cognito:username"]}
              </Descriptions.Item>
              <Descriptions.Item label="Cognito Preferred Role">
                {user.signInUserSession.idToken.payload["cognito:preferred_role"]}
              </Descriptions.Item>
              <Descriptions.Item label="Email Verified?">
                {yesNoTag(user.signInUserSession.idToken.payload.email_verified)}
              </Descriptions.Item>
            </Descriptions>}
          </Row>
        </Col>
         <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>QuickSite Config</Title>
          </Row>
          <Row>
              <Descriptions
                bordered
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                >
              <Descriptions.Item label="Can you retrieve a QuickSight URL?">
                {yesNoTag(rawQSData)}
              </Descriptions.Item>
              </Descriptions>
          </Row>
        </Col>
         {/* <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>Title</Title>
          </Row>
          <Row>
            <Row align="center">
              <Spin size="large" />
            </Row>
          </Row>
        </Col> */}
          <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>User Configurations</Title>
          </Row>
         <Row>
            <Collapse defaultActiveKey={[]}>
                <Panel 
                  style={{minWidth: "600px", maxWidth: "900px"}}
                  header={"Raw User Data"} 
                  key={"raw-user-data"}
                  // extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={user}  theme="hopscotch"/>
                </Panel>
                <Panel 
                  style={{minWidth: "600px", maxWidth: "900px"}}
                  header={"Main Siden Token"} 
                  key={"main-siden-token"}
                  // extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={{sidenToken, parsedSidenToken}}  theme="hopscotch"/>
                </Panel>
                <Panel 
                  style={{minWidth: "600px", maxWidth: "900px"}}
                  header={"Siden MNO Token"} 
                  key={"siden-mno-token"}
                  // extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={{sidenMNOToken, parsedSidenMNOToken}}  theme="hopscotch"/>
                </Panel>
                <Panel 
                  style={{minWidth: "600px", maxWidth: "900px"}}
                  header={"Cognito Access Token"} 
                  key={"cognito-access-token"}
                  // extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={user.signInUserSession.accessToken}  theme="hopscotch"/>
                </Panel>
                <Panel 
                  style={{minWidth: "600px", maxWidth: "900px"}}
                  header={"Cognito ID Token"} 
                  key={"cognito-id-token"}
                  // extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={user.signInUserSession.idToken}  theme="hopscotch"/>
                </Panel>
            </Collapse>
          </Row>
        </Col>
        {/* <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>SCE Configurations</Title>
          </Row>
          {SCEConfigs.length != 0  ? (<Row>
            <Collapse defaultActiveKey={[]}>
              {SCEConfigs.map(SCEConfig => {
                return (
                <Panel 
                  style={{minWidth: "600px"}}
                  header={SCEConfig.policyId} 
                  key={SCEConfig.policyId}
                  extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={SCEConfig}  theme="hopscotch"/>
                </Panel>
                )
              })}
            </Collapse>
          </Row>) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Col>
        <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>UI Configurations</Title>
          </Row>
          {UIConfigs.length != 0 ? (<Row>
            <Collapse defaultActiveKey={[]}>
              {UIConfigs.map(SCEConfig => {
                return (
                <Panel 
                  style={{minWidth: "600px"}}
                  header={SCEConfig.policyId} 
                  key={SCEConfig.policyId}
                  extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={SCEConfig}  theme="hopscotch"/>
                </Panel>
                )
              })}
            </Collapse>
          </Row>) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Col>
        <Col style={{marginTop: "20px"}}>
            
          <Row>
            <Title level={4}>Disk Configuration</Title>
          </Row>
          <Row>
          {diskConfig ? (
        <Descriptions
          bordered
          column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
          >
              <Descriptions.Item label="hubPurgeItemsBunchCount">
                {diskConfig.hubPurgeItemsBunchCount}
              </Descriptions.Item>
              <Descriptions.Item label="minDiskSpaceRatio">
                {diskConfig.minDiskSpaceRatio}
              </Descriptions.Item>
              <Descriptions.Item label="preventDeliveryCycleBufferSizeMb">
                {diskConfig.preventDeliveryCycleBufferSizeMb}
              </Descriptions.Item>
              <Descriptions.Item label="purgeRunAvailableDiskSpaceSizeMb">
                {diskConfig.purgeRunAvailableDiskSpaceSizeMb}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Row>
        </Col> */}
        </Col>
      )
}

export default DashboardConfigs;
