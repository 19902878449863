import React, { useState, useEffect, useContext } from "react";

import Amplify, { Auth } from "aws-amplify";

import { Layout, Menu, Dropdown, Row, Typography, Spin } from "antd";

import {apiGatewayGetDashboardEmbedUrl, asyncGetQSUrl} from "../Shared/quickSight.js"

import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { REACT_APP_QS_HOME_PAGE } from "../constants.js";
// const dashboard = embedDashboard(options);



const {Title} = Typography
const { Header, Content, Footer, Sider } = Layout;

// function embedDashboard(url) {
//     var containerDiv = document.getElementById("dashboardContainer");
//     var options = {
//         url: url,
//         container: containerDiv,
//         parameters: {
//             country: 'United States'
//         },
//         scrolling: "no",
//         height: "1000px",
//         width: "100%"
//     };
//     dashboard = QuickSightEmbedding.embedDashboard(options);
//     dashboard.on('error', onError);
//     dashboard.on('load', onDashboardLoad);
//   }

// HUBSTATUS URLS// 45b1c89c-9314-4b2f-9084-c798c2ee19ec
// Cell Status 82c04c88-4659-469e-9ee3-ec1791a29ef6

function Home() {
  const [quickSight, setQuickSight] = useState(null);

  const dashboardId = REACT_APP_QS_HOME_PAGE;

   useEffect(() => {
     Auth.currentAuthenticatedUser().then((user) => {
      const parameters = {
        dashboardId:  dashboardId,
        dashboardRegion:  "us-east-2",
        openIdToken: user.signInUserSession.idToken.jwtToken,
        resetDisabled: true,
        undoRedoDisabled: true,
      }
      asyncGetQSUrl(parameters, setQuickSight).then(url=> {
        var containerDiv = document.getElementById("dashboardContainer")
        if (!containerDiv) {return;}
        var options = {
          url: url,
          container: containerDiv,
          parameters: {
              country: 'United States'
          },
          scrolling: "no",
          height: "1000px",
          width: "100%"
        } 
       QuickSightEmbedding.embedDashboard(options)
      })
    })
  }, [])

  return (
    <React.Fragment key={dashboardId}>
        <Title level={2} >Welcome to the Siden Dashboard!</Title>
        {quickSight ? 
        <div key={dashboardId+"a"} id="dashboardContainer" /> : 
<Row align="center">
           <Spin size="large" />
         </Row>}
      </React.Fragment>


  );
}

export default Home;
