// React
import React, { useEffect, useState } from "react";

import { generateAuthHeader } from "../authHeader";

import { Auth } from "aws-amplify";
import { PlusOutlined } from "@ant-design/icons";

import { Row, Button, message, notification, Result , Descriptions, Spin, Popconfirm, Table, Col, Collapse, Tag} from "antd";

import { Link } from "react-router-dom";

import moment from "moment"

import { loadAllSCEConfigs, loadAllUIConfigs, loadDiskConfig} from "./newApiCalls.js";
import { useParams } from "react-router";
import Title from "antd/lib/typography/Title";

import ReactJson from 'react-json-view'

// use the component in your app!
const {Panel} = Collapse;


const axios = require("axios");

const HubConfigs = (props) => {
  const [hubConfigs, setHubConfigs] = useState([])
  const [SCEConfigs, setSCEConfigs] = useState([])
  // const [UIConfigs, setUIConfigs] = useState([])
  const [diskConfig, setDiskConfig] = useState({})
  // const [user, setUser] = useState({})
  // const [activePurchase, setActivePurchase] = useState([])
  // const [historyPurchases, setHistoryPurchases] = useState([])
  // const [catalog, setCatalog] = useState({});

// const {sidenAccountId} = useParams()

  // useEffect(() => {
    // loadAccountBySID(setAccount, sidenAccountId).then(account=>{
    //   if(account) {
    //     loadHubUser(setUser, account.hubId)
    //   }
  //   })
  // }, [])

    useEffect(() => {
      // loadAllSCEConfigs(setSCEConfigs)
    }, [])
    // useEffect(() => {
    //   loadAllUIConfigs(setUIConfigs)
    // }, [])
    useEffect(() => {
      // loadDiskConfig(setDiskConfig)
    }, [])


  // const columns = [
  //   {
  //     key: "plan",
  //     title: "Plan",
  //     dataIndex: "plan",
  //   },
  //   {
  //     key: "name",
  //     title: "name",
  //     dataIndex: "plan",
  //     render: (datum) => catalog[datum],
  //   },
  //   {
  //     key: "isPromo",
  //     title: "isPromo?",
  //     dataIndex: "isPromo",
  //     render: (datum) => String(datum),
  //   },
  //   {
  //     key: "start",
  //     title: "Start",
  //     dataIndex: "start",
  //   },
  //   {
  //     key: "end",
  //     title: "End",
  //     dataIndex: "end",
  //   },
  // ]

  console.log({SCEConfigs})

  return (
    <Col>
       <Title level={2}>Device Configuration</Title>
        <Row>
          {/* <Button type="primary" onClick={forceRefresh}>Refresh</Button> */}
        </Row>
        <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>SCE Configurations</Title>
          </Row>
          {SCEConfigs.length != 0  ? (<Row>
            <Collapse defaultActiveKey={[]}>
              {SCEConfigs.map(SCEConfig => {
                return (
                <Panel 
                  style={{minWidth: "600px"}}
                  header={SCEConfig.policyId} 
                  key={SCEConfig.policyId}
                  extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={SCEConfig}  theme="hopscotch"/>
                </Panel>
                )
              })}
            </Collapse>
          </Row>) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Col>
        {/* <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>UI Configurations</Title>
          </Row>
          {UIConfigs.length != 0 ? (<Row>
            <Collapse defaultActiveKey={[]}>
              {UIConfigs.map(SCEConfig => {
                return (
                <Panel 
                  style={{minWidth: "600px"}}
                  header={SCEConfig.policyId || "Policy ID Blank"} 
                  key={SCEConfig.policyId}
                  extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={SCEConfig}  theme="hopscotch"/>
                </Panel>
                )
              })}
            </Collapse>
          </Row>) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Col> */}
        <Col style={{marginTop: "20px"}}>
            
          <Row>
            <Title level={4}>Disk Configuration</Title>
          </Row>
          <Row>
          {diskConfig ? (
        <Descriptions
          bordered
          column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
          >
              <Descriptions.Item label="hubPurgeItemsBunchCount">
                {diskConfig.hubPurgeItemsBunchCount}
              </Descriptions.Item>
              <Descriptions.Item label="minDiskSpaceRatio">
                {diskConfig.minDiskSpaceRatio}
              </Descriptions.Item>
              <Descriptions.Item label="preventDeliveryCycleBufferSizeMb">
                {diskConfig.preventDeliveryCycleBufferSizeMb}
              </Descriptions.Item>
              <Descriptions.Item label="purgeRunAvailableDiskSpaceSizeMb">
                {diskConfig.purgeRunAvailableDiskSpaceSizeMb}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Row>
        </Col>
        </Col>
      )
}

export default HubConfigs;
