import { BASE_URL, API_URL } from "../constants.js";
import { Auth } from "aws-amplify";
import { generateAuthHeader } from "../authHeader";
import { notification, message } from "antd";

import {
  requestForwarder,
  requestForwarderAPI,
  requestForwarderAPISimple,
  requestForwarderPost,
  requestPost,
  requestGet,
  requestDelete,
  requestDeleteWithPayload
} from "../newApiCallsHelper";

export const createNewAccount = (accountData) => {
  console.log({accountData})

  return requestPost(`${API_URL}/grpc/v1/accounts/users/partners`, 
    accountData
  ).then(
    (result) => {
     message.success(`Dashboard Account Created`);
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Creating Account",
        description: ""
      });
    }
  );
};

// export const decryptSingleCellId = ({encryptedCellId, mnoId }) => {
//   return requestGet(`${API_URL}/grpc/v1/networktopology/cell/decrypt?mnoId=${mnoId}&encryptedCellId=${encryptedCellId}`).then(
//     (result) => {
//       console.log({result})
//      message.success(`Decrypted Cell ID`);
//      return result.data.data[0]
//     },
//     (error) => {
//       console.log(error);
//         notification["error"]({
//         message: "Error on Decrypting Cell ID",
//         description: ""
//       });
//     }
//   );
// };

export const decryptSingleCellId = ({encryptedCellId }) => {
  return requestPost(`${API_URL}/grpc/v1/networktopology/cell/decrypt`, {
  "encryptedCellId": [
    encryptedCellId
  ]
}).then(
    (result) => {
      console.log({result})
     message.success(`Decrypted Cell ID`);
     return result.data.data[0]
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Decrypting Cell ID",
        description: ""
      });
    }
  );
};

export const encryptSingleCellId = ({decryptedCellId, mnoId }) => {
  return requestGet(`${API_URL}/grpc/v1/networktopology/cell/encrypt?mnoId=${mnoId}&cellId=${decryptedCellId}`).then(
    (result) => {
      console.log({result})
     message.success(`Encrypted Cell ID`);
     return result.data.data[0]
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Encrypting Cell ID",
        description: ""
      });
    }
  );
};