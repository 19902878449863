// React
import React, { useEffect, useState } from "react";

import { generateAuthHeader } from "../authHeader";

import { Auth } from "aws-amplify";
import { PlusOutlined,DeleteOutlined } from "@ant-design/icons";

import { Row, Button, message, notification, Result , Descriptions, Spin, Popconfirm, Table, Col} from "antd";

import { BASE_URL } from "../constants.js";
import HubCreateForm from "./HubCreateForm.js";
import SubscriberTable from "./SubscriberTable.js";
import { Link } from "react-router-dom";

import moment from "moment"

import {
  loadAccountBySID,
  createNewAccountService,
  // loadAllCells,
  loadSubscription,
  loadActivePurchases,
  deleteNewAccountService,
  loadAllCells,
  loadHistoryPurchases,
  loadCatalog,
  loadHubUser,
  editNewAccountService,
  editSidenAccount,
  changeHubNewAccountService,
  postProductStart,
  postProductEnd
} from "./newApiCalls.js";
import { useParams } from "react-router";
import Title from "antd/lib/typography/Title";
import HubEditForm from "./editForm";
import EditHub from "./editHub";
import AssignSubscription from "./AssignSubscription";

const axios = require("axios");

const SubscriberDetails = (props) => {
  const [account, setAccount] = useState(null)
  const [subscription, setSubscription] = useState({})
  const [user, setUser] = useState({})
  const [activePurchase, setActivePurchase] = useState([])
  const [historyPurchases, setHistoryPurchases] = useState([])
  const [catalog, setCatalog] = useState({});
  const [catalogDetails, setCatalogDetails] = useState([]);
  const [visible, setVisible] = useState(false);
  const [changeHub, setChangeHub] = useState(false);
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);

  const {sidenAccountId} = useParams();
  const {debugMode} = props; 

  const reloadData = () => {
    loadAccountBySID(setAccount, sidenAccountId).then(account=>{
      if(account) {
        loadHubUser(setUser, account.hubId)
      }
    }
    )
    //  loadSubscription(setSubscription,sidenAccountId)
    //   loadActivePurchases(setActivePurchase,sidenAccountId)
    //   loadHistoryPurchases(setHistoryPurchases,sidenAccountId)
    //   loadCatalog(setCatalog,sidenAccountId, setCatalogDetails)
  }

  const onEdit = (values: any) => {
    console.log('Received values of form: ', values);

    editNewAccountService(account, {
      ...account,
      ...values
    }).then(v=> reloadData())

    setVisible(false);
  };
 
  const onChangeHub = (values: any) => {
    console.log('Received values of form: ', values);

    // editSidenAccount(account.hubId,values.hubId, values.sidenAccountId).then(v=> reloadData())

    changeHubNewAccountService(values).then(v=> reloadData())

    setChangeHub(false);
  };

  const onAssignSubscription = (values: any) => {
    console.log('Received values of form: ', values);

    // editSidenAccount(account.hubId,values.hubId, values.sidenAccountId).then(v=> reloadData())

    postProductStart(values).then(v=> reloadData())

    setSubscriptionModalOpen(false);
  };

  const onEndSubscription = ({plan, billingId}) => {
    postProductEnd({productPlanId: plan, 
      sidenAccountId, billingId}).then(v=> reloadData())
    setSubscriptionModalOpen(false);
  };
 

  useEffect(() => {
    loadAccountBySID(setAccount, sidenAccountId).then(account=>{
      if(account) {
        loadHubUser(setUser, account.hubId)
      }
    })
  }, [])

  // useEffect(() => {
  //   loadSubscription(setSubscription,sidenAccountId)
  // }, [])

  // useEffect(() => {
  //   loadActivePurchases(setActivePurchase,sidenAccountId)
  // }, [])

  // useEffect(() => {
  //   loadHistoryPurchases(setHistoryPurchases,sidenAccountId)
  // }, [])

  // useEffect(() => {
  //   loadCatalog(setCatalog,sidenAccountId, setCatalogDetails)
  // }, [])

  // Estimate Time Zone
  const IANAtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const columns = [
    {
      key: "plan",
      title: "Plan",
      dataIndex: "plan",
    },
    {
      key: "name",
      title: "name",
      dataIndex: "plan",
      render: (datum) => catalog[datum],
    },
    {
      key: "billingId",
      title: "Billing ID",
      dataIndex: "billingId",
    },
    {
      key: "isPromo",
      title: "isPromo?",
      dataIndex: "isPromo",
      render: (datum) => String(datum),
    },
    {
      key: "start",
      title: "Start",
      dataIndex: "start",
      render: (datum) => `${moment(datum).format("L HH:mm:ss Z")} (${IANAtimezone})`,
    },
    {
      key: "end",
      title: "End",
      dataIndex: "end",
      render: (datum) => `${moment(datum).format("L HH:mm:ss Z")} (${IANAtimezone})`,
    },
 ...(debugMode ? [{
        key: "terminate",
        title: "Terminate",
        render: (datum) =>
        (
            <Popconfirm
              title="Are you sure you want to terminate this subscription?"
              onConfirm={() =>
                onEndSubscription(datum)
              }
              onCancel={() => console.log("cancelled")}
              okText="End Subscription"
            >
              <Button type="danger">End Subscription</Button>
            </Popconfirm>
          ),
      }] : []),
  ]

  const columnsHistory = [
    {
      key: "plan",
      title: "Plan",
      dataIndex: "plan",
    },
    {
      key: "name",
      title: "name",
      dataIndex: "plan",
      render: (datum) => catalog[datum],
    },
        {
      key: "billingId",
      title: "Billing ID",
      dataIndex: "billingId",
    },
    {
      key: "isPromo",
      title: "isPromo?",
      dataIndex: "isPromo",
      render: (datum) => String(datum),
    },
    {
      key: "start",
      title: "Start",
      dataIndex: "start",
      render: (datum) => `${moment(datum).format("L HH:mm:ss Z")} (${IANAtimezone})`,
    },
    {
      key: "end",
      title: "End",
      dataIndex: "end",
      render: (datum) => `${moment(datum).format("L HH:mm:ss Z")} (${IANAtimezone})`,
    },
    {
      key: "sidenTransactionId",
      title: "Siden Transaction ID",
      dataIndex: "sidenTransactionId",
    },
    {
      key: "pgTransactionId",
      title: "Payment Gateway Transaction ID",
      dataIndex: "pgTransactionId",
    },
    {
      key: "pgTransactionCreatedAt",
      title: "Transaction Created At",
      dataIndex: "pgTransactionCreatedAt",
    },
  ]

    const forceRefresh = () => {
    setAccount(null)
      loadAccountBySID(setAccount, sidenAccountId)
    // loadSubscription(setSubscription,sidenAccountId)
    // loadActivePurchases(setActivePurchase,sidenAccountId)
    // loadHistoryPurchases(setHistoryPurchases,sidenAccountId)
      
  }

  if(!account) {
 return (
    <React.Fragment>
         <Title level={2}>Subscriber Details</Title>
        <Row>
          <Button type="primary" onClick={forceRefresh}>Refresh</Button>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Spin size="large" />
        </Row>
      </React.Fragment>
    ) 
  }

  return (
    <React.Fragment>
       <Title level={2}>Subscriber Details</Title>
        <Row justify="space-between">
          <Button type="primary" onClick={forceRefresh}>Refresh</Button>
          <Col>
          <Button
            // type="primary"
            onClick={() => {
              setVisible(true);
            }}
            >Edit</Button>
          {/* <Button
            type="primary"
            onClick={() => {
              setChangeHub(true);
            }}
            >Change Hub</Button> */}
            </Col>
        </Row>
      <Row style={{marginTop: "20px"}}>
        <Descriptions
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
          {account ? (
            <React.Fragment>
              <Descriptions.Item label="MNO Account ID (MSISDN)">
                {account.mnoAccountId}
              </Descriptions.Item>
              <Descriptions.Item label="Hub ID">
              <Link to={`/${debugMode ? "debug_devices" : "device_status"}/${account.hubId}`}>{account.hubId}</Link>
              </Descriptions.Item>
              {/* <Descriptions.Item label="IMEI">
                {account.imei}
              </Descriptions.Item> */}
              <Descriptions.Item label="ICCID">
                {account.iccid}
              </Descriptions.Item>
              <Descriptions.Item label="Siden Account ID">
                {account.sidenAccountId}
              </Descriptions.Item>
              {/* <Descriptions.Item label="Auth0 User ID">
                {String(account.auth0UserId)}
              </Descriptions.Item>
              <Descriptions.Item label="Auth0 User Email">
                {String(user.email)}
              </Descriptions.Item> */}
              <Descriptions.Item label="MNO ID">
                {account.mnoId}
              </Descriptions.Item>
              {/* <Descriptions.Item label="MNO Alias">
                {account.mnoAlias}
              </Descriptions.Item> */}
              <Descriptions.Item label="SCE Hostname">
                {account.sceHostname}
              </Descriptions.Item>
              <Descriptions.Item label="Created At">
                {`${moment(account.createdAt).format("L HH:mm:ss Z")} (${IANAtimezone})`}
              </Descriptions.Item>
              <Descriptions.Item label="Updated At">
                {`${moment(account.updatedAt).format("L HH:mm:ss Z")} (${IANAtimezone})`}
              </Descriptions.Item>
            </React.Fragment>
          ) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Descriptions>
        </Row>
        {/* <Row style={{"marginTop": "20px"}}>
        <Descriptions
          title="Subscription Details"
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
          {subscription ? (
            <React.Fragment>
              <Descriptions.Item label="Plan ID">
                {subscription.planId || "None"}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {catalog[subscription.planId] || "None"}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                {subscription.status}
              </Descriptions.Item>
            </React.Fragment>
          ) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Descriptions>
        </Row>
      {debugMode && catalogDetails && <Row style={{"marginTop": "20px"}}>
          <Button
            type="primary"
            onClick={() => {
              setSubscriptionModalOpen(true);
            }}
            >Manually Add Subscription</Button>
          </Row>}  
        <Row style={{"marginTop": "20px"}}>
          <Table     title={() => 'Active Purchases'} pagination={false} dataSource={activePurchase} columns={columns} />
        </Row> 
        <Row style={{"marginTop": "20px"}}>
          <Table      title={() => 'History of Purchases'} pagination={false} dataSource={historyPurchases} columns={columnsHistory} />
        </Row> */}
       <Row style={{"marginTop": "20px"}}>
          <Popconfirm
          title="Are you sure you want to terminate this account?"
          onConfirm={() => {
            deleteNewAccountService(account);
          }}
          onCancel={() => console.log("cancelled")}
          okText="Delete"
          >
          <Button type="danger">Delete Account</Button>
        </Popconfirm>
        </Row>  
        {account && <HubEditForm
            visible={visible}
            onEdit={onEdit}
            onCancel={() => {
              setVisible(false);
            }}
            account={account}
            debugMode={debugMode}
        />}
        {account && <EditHub
            visible={changeHub}
            onEdit={onChangeHub}
            onCancel={() => {
              setChangeHub(false);
            }}
            account={account}
            debugMode={debugMode}
        />}
        {account && catalogDetails && <AssignSubscription
            visible={subscriptionModalOpen}
            onEdit={onAssignSubscription}
            onCancel={() => {
              setSubscriptionModalOpen(false);
            }}
            account={account}
            catalogDetails={catalogDetails}
            debugMode={debugMode}
        />}
        </React.Fragment>
      )
}

export default SubscriberDetails;
