import React from "react";

import { generateAuthHeader } from "../authHeader";
import { Auth } from "aws-amplify";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Table,
  Row,
  Popconfirm,
  Button,
  Input,
  Radio,
  Modal,
  InputNumber,
  notification,
  message,
  Result,
} from "antd";

import { BASE_URL } from "../constants.js";
import CellSearchBar from "./CellSearchBar";
import { withRouter } from "react-router-dom";
import { getColumnSearchProps } from "../SubscriberManagement/search";
import { createCellViaTopology, deleteCellViaTopology, loadAllCellsViaTopologyVirtual } from "../Shared/cellCallsViaDC";

import CellDormantCapacity from "./CellDormantCapacity"

const axios = require("axios");

const CellCreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const { visible, onCancel, onCreate, form, confirmLoading } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Add a new cell"
          okText="Create"
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={confirmLoading}
        >
          <Form layout="vertical">
            <Form.Item label="Cell ID">
              {getFieldDecorator("cellId", {
                rules: [
                  {
                    required: true,
                    message:
                      "Virtual cells must start with 99, minimum 3 characters",
                    pattern: "^99[0-9]+",
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator("description")(<Input />)}
            </Form.Item>
            <Form.Item
              label="Cell Type"
              className="collection-create-form_last-form-item"
            >
              {getFieldDecorator("type", {
                initialValue: true,
              })(
                <Radio.Group>
                  <Radio value={"VIRTUAL"}>virtual</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class CellManagement extends React.Component {
  state = {
    confirmLoading: false,
    layer: false,
    dormCapModal: false,
    cellData: [],
    paging: { cursors: { before: "", after: "" } },
    isLoaded: false,
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    const setAllCellData = (cellData) => {
      this.setState({
        cellData: cellData,
        isLoaded: true,
      });
    };
    loadAllCellsViaTopologyVirtual(setAllCellData, (v) => v, true);
  };

  // load = () => {
  //   Auth.currentAuthenticatedUser()
  //     .then((user) =>
  //       axios.get(`${BASE_URL}/api/v1/scgw/cell`, {
  //         ...generateAuthHeader(user),
  //         params: {
  //           limit: 1000,
  //         },
  //       })
  //     )
  //     .then(({ data }) => {
  //       this.setState({
  //         isLoaded: true,
  //         cellData: data.data,
  //         paging: data.paging,
  //       });
  //       console.log(data);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     });
  // };

  createCell = (cellData) => {
    return createCellViaTopology(cellData)
      .then((response) => {
        console.log(response);
        message.success("Virtual Cell Created");
      })
      .catch(function (error) {
        // handle error
        notification["error"]({
          message: "Error on Cell Creation",
          description: "Something went wrong",
        });
        console.log(error);
      });
  };

  deleteCell = (cellId) => {
    return deleteCellViaTopology(cellId)
      .then((response) => {
        message.success("Cell Deleted");
        console.log(response);
      })
      .catch(function (error) {
        notification["error"]({
          message: "Error on Cell Deletion",
          description: "Something went wrong",
        });
        console.log(error);
      })
      .then(function () {
        console.log("always executed");
      });

    console.log("delete Cell");
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  closeModal = () => {
    this.setState({ layer: false });
  };

  closeDormCapModal = () => {
    this.setState({ dormCapModal: null });
  };
  openDormCapModal = (cellId) => {
    this.setState({ dormCapModal: cellId });
  };

  handleCreate = () => {
    this.setState({ confirmLoading: true });
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        this.setState({ confirmLoading: false });
        return;
      }
      const virtualCell = {
        cellId: values.cellId,
        sectorId:  values.cellId,
        serviceArea:  values.cellId,
        plmn: "",
        mnoId: "",
        description: values.description,
        type: values.type,
        filterState: "INCLUDED"
      }
      console.log({virtualCell})
      this.createCell(virtualCell).then((v) => this.load());
      console.log("Received values of form: ", values);
      form.resetFields();
      this.setState({ confirmLoading: false, layer: false });
    });
  };

  setSearchText = (text) => {
    this.setState({ searchText: text });
  };

  setSearchInput = (input) => {
    console.log({ input });
    return (input) => this.setState({ searchInput: input });
  };

  setSearchedColumn = (col) => {
    this.setState({ searchedColumn: col });
  };

  render() {
    const { cellData, dormCapModal } = this.state;
    const { vpnStatus } = this.props;

    const { searchText, searchedColumn, searchInput } = this.state;
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setSearchText(selectedKeys[0]);
      this.setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      this.setSearchText("");
    };

    const generateColumnSearchFunctions = (dataIndex) => ({
      dataIndex: dataIndex,
      handleSearch: handleSearch,
      handleReset: handleReset,
      searchedColumn,
      searchText,
      setSearchInput: this.setSearchInput,
      searchInput: searchInput,
    });

    const setFoundCell = (cellId) => {
      this.setSearchText(cellId);
      this.setSearchedColumn("cellId");
    };

    const columns = [
      {
        key: "cellId",
        dataIndex: "cellId",
        title: "Cell ID",
        ...getColumnSearchProps(generateColumnSearchFunctions("cellId")),
      },
      {
        key: "description",
        dataIndex: "description",
        title: "Description",
      },
      // {
      //   key: "enodeb",
      //   dataIndex: "enodeb",
      //   title: "eNodeB",
      // },
      // {
      //   key: "mcc",
      //   dataIndex: "mcc",
      //   title: "MCC",
      // },
      // {
      //   key: "mnc",
      //   dataIndex: "mnc",
      //   title: "MNC",
      // },
            {
        key: "type",
        dataIndex: "type",
        title: "Type",
      },
      // {
      //   key: "usage",
      //   dataIndex: "usage",
      //   title: "Usage",
      // },
      // {
      //   key: "virtual",
      //   dataIndex: "virtual",
      //   title: "Virtual",
      //   render: (datum) => String(datum),
      //   filters: [
      //     { text: "True", value: true },
      //     { text: "False", value: false },
      //   ],
      //   filterMultiple: false,
      //   onFilter: (value, record) => record.virtual === value,
      // },
      {
        key: "info",
        title: "Info",
        render: (datum) => (
          <InfoCircleOutlined
            onClick={() =>
              this.props.history.push(
                `${this.props.location.pathname}/${datum.cellId}`
              )
            }
          />
        ),
      },
      {
        key: "dormcap",
        title: "Dormcap",
        render: (datum) => (
          <InfoCircleOutlined
            onClick={() =>
              this.openDormCapModal(datum.cellId)
              // this.props.history.push(
              //   `${this.props.location.pathname}/${datum.cellId}/dormcap `
              // )
            }
          />
        ),
      },
      {
        key: "delete",
        title: "Delete",
        render: (datum) =>
          datum.type == "VIRTUAL" ? (
            <Popconfirm
              title="Are you sure you want to delete this cell?"
              onConfirm={() =>
                this.deleteCell(datum.cellId).then((v) => this.load())
              }
              onCancel={() => console.log("cancelled")}
              okText="Delete"
            >
              <DeleteOutlined />
            </Popconfirm>
          ) : null,
      },
    ];

    console.log(cellData);

    return vpnStatus ? (
      <div
        style={{
          paddingLeft: "55px",
          paddingRight: "55px",
        }}
      >
        <Row style={{ margin: "10px" }} justify="end">
          <Button
            icon={<PlusOutlined />}
            onClick={() => this.setState({ layer: true })}
          />
        </Row>
        <Row style={{ margin: "10px" }}>
          <CellSearchBar setFoundCell={setFoundCell} />
        </Row>
        <Row
          style={{
            margin: "10px",
          }}
        >
          <Table
            columns={columns}
            defaultExpandAllRows={true}
            dataSource={cellData}
            bordered
            pagination={false}
          />
        </Row>
        <Modal
          visible={dormCapModal}
          title="Individual Adjustment of Dormcap"
          okText="Ok"
          onCancel={this.closeDormCapModal}
           width={1000}
          onOk={this.closeDormCapModal}
          // confirmLoading={confirmLoading}
        >
          {dormCapModal && <CellDormantCapacity cellId={dormCapModal}/>}
        </Modal>
        <CellCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.layer}
          onCancel={this.closeModal}
          onCreate={this.handleCreate}
          confirmLoading={this.state.confirmLoading}
        />
      </div>
    ) : (
      <Row style={{ margin: "10px" }} justify="center">
        <Result status="warning" title="Connection failed, check your VPN" />
      </Row>
    );
  }
}

export default withRouter(CellManagement);
