import React from 'react';

import Iframe from '../Shared/Iframe';
import { DEBUG_DASHBOARD_GRAFANA } from '../constants';

const debugDashboardIframe = `<iframe _ngcontent-c6="" class="iframe-grafana ng-star-inserted" src="${DEBUG_DASHBOARD_GRAFANA}?orgId=1&amp;from=now-12h&amp;refresh=5s" style="position: absolute;height: calc(100% - 65px);width: 100%;"></iframe>`;

const DebugDashboard = () => <Iframe iframe={debugDashboardIframe} />;

export default DebugDashboard;
