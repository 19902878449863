// React
import React from "react";

import { generateAuthHeader } from "../authHeader";

import { Auth } from "aws-amplify";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import { Row, Button, message, notification, Result } from "antd";

import { API_URL, BASE_URL, REACT_APP_SCE } from "../constants.js";
import HubCreateForm from "./HubCreateForm.js";
import SubscriberTable from "./SubscriberTable.js";

import {
  loadAccountsNew,
  createNewAccountService,
  // loadAllCells,
  loadAllCells,
  postPromoSubscription,
} from "./newApiCalls.js";
import { postCPEMappingNew, postTopologyMapping } from "../Cell/newApiCalls";
import { loadAllCellsViaDC } from "../Shared/cellCallsViaDC";
import HubSearchBar from "./HubSearchBar";
import { loadGenericPaginator, requestPost } from "../newApiCallsHelper";

const axios = require("axios");

class HubManagement extends React.Component {
  state = {
    subscriberData: [],
    cellData: [],
    before: "",
    after: "",
    path: `${API_URL}/grpc/v1/accounts/hubs`,
    searchBar: {param: null, value: null},
    // hardParams: this.props.debugMode ? {param: "notDeviceType", value: "REAL"} : {param: "deviceType", value: "REAL"},
    hardParams: {},
    paging: { cursors: { before: "", after: "" } },
    layer: false,
    showSidebar: false,
    confirmLoading: false,
    searchText: "",
    searchedColumn: "",
    editingKey: "",
  };

  componentDidMount() {
    // this.reloadData();
    this.loader()
  }

  reloadData = () => {
    this.load("", "")();
  };

  load = (before, after) => {
    const setAllCells = (cellData) => {
      this.setState({
        cellData: cellData,
      });
    };
    // loadAllCells(setAllCells, v => v);
    loadAllCellsViaDC(setAllCells, (v) => v);

    return () => {
      const pagination = before ? `before=${before}&` : `after=${after}&`;

      const setAccounts = (subscriberData) => {
        this.setState({
          subscriberData: subscriberData,
        });
      };

      const setIsLoaded = (subscriberData) => {
        this.setState({
          isLoaded: true,
        });
      };

      loadAccountsNew(setAccounts, setIsLoaded);
    };
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  closeModal = () => {
    this.setState({ layer: false });
  };

  handleCreate = () => {
    this.setState({ confirmLoading: true });
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        this.setState({ confirmLoading: false });
        return;
      }

      const {
        mnoAccountId,
        hubId,
        imsi,
        iccid,
        status,
        mnoAlias,
        cellId,
        cpeIp,
        virtual,
        deviceType,
        deviceVersion, 
        activatePromo
      } = values;

      const sceHostname = `${hubId}.sce.${REACT_APP_SCE}`

      const newAccountInfo = { mnoAccountId, hubId, imsi,
        iccid, mnoAlias, status, sceHostname, deviceType, deviceVersion };

      const onAccountCreationSuccess = (sidenAccountId) => {
        // this.reloadData();
        const newAccount = {
          ...newAccountInfo,
          sidenAccountId,
          key: sidenAccountId,
          id: sidenAccountId,
        };
        form.resetFields();
        this.setState({
          confirmLoading: false,
          layer: false,
          subscriberData: [...this.state.subscriberData, newAccount],
        });
        // if (deviceType == "VIRTUAL") {
        //   postTopologyMapping("990000000700", hubId);
        //   // postCPEMappingNew({ cpeIp, cellId, imsi, virtual });
        // }
        // if (activatePromo && deviceType != "REAL") {
        //   postPromoSubscription("XLA-BSCP-MN", sidenAccountId);  
        // }
         
      };

      const onAccountCreationFailure = () => {
        this.setState({ confirmLoading: false });
      };

      createNewAccountService(
        newAccountInfo,
        onAccountCreationSuccess,
        onAccountCreationFailure
      );
    });
  };

   setAccounts = (subscriberData) => {
    this.setState({
      subscriberData: subscriberData,
    });
  };
   setBefore = (before) => {
    this.setState({
      before: before,
    });
  };
   setPath = (path) => {
    this.setState({
      path: path,
    });
  };
   setAfter = (after) => {
    this.setState({
      after: after,
    });
  };
   setSearchBar = (searchBar) => {
    this.setState({
      searchBar: searchBar,
    });
  };


  loader = () => {
    this.loadViaParam("", "")
  } 
  loadNext = () => {
    const {before, after, path, searchBar, hardParams} = this.state
    loadGenericPaginator(path, "", after, 10, searchBar, this.setAccounts, (v)=> console.log(v), this.setBefore, this.setAfter, hardParams)
  }
  loadPrev = () => {
    const {before, after, path, searchBar, hardParams} = this.state
    loadGenericPaginator(path, before, "", 10, searchBar, this.setAccounts, (v)=> console.log(v), this.setBefore, this.setAfter, hardParams)
  }

  loadViaParam = (param, value) => {
    const { path, hardParams } = this.state
    this.setSearchBar({param: param, value: value})
    return loadGenericPaginator(path, "", "", 10, {param: param, value: value}, this.setAccounts, (v)=> console.log(v), this.setBefore, this.setAfter, hardParams)
  }

  render() {
    const { subscriberData, cellData,searchBar } = this.state;
    const { vpnStatus, debugMode } = this.props;


    return (
      <div
        style={{
          paddingRight: "55px",
        }}
      >
        <HubSearchBar setAccounts={this.setAccounts} setSearchBar={this.setSearchBar} loadViaParam={this.loadViaParam}/>
        <Row style={{ margin: "10px" }} justify="space-between">
          <Button type="primary" onClick={this.loader} disabled={!searchBar.param}>
            Reset
          </Button>
          {debugMode && <Button
            icon={<PlusOutlined />}
            onClick={() => this.setState({ layer: true })}
          />}
        </Row>
        {vpnStatus ? (
          <Row style={{ margin: "10px" }}>
            <SubscriberTable
              subscriberData={subscriberData}
              reloadData={this.reloadData}
              debugMode={this.props.debugMode}
              footer={()=> (
                <Row justify="space-between">
                  <Button
                    icon={<MinusOutlined />}
                    type="primary"
                    onClick={this.loadPrev}
                    disabled={!this.state.before}
                    >Back</Button>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={this.loadNext}
                    disabled={!this.state.after}
                  >Next</Button>
              </Row>
              )}
            />
          </Row>
        ) : (
          <Row style={{ margin: "10px" }} justify="center">
            <Result
              status="warning"
              title="Connection failed, check your VPN"
            />
          </Row>
        )}        
       
        <HubCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.layer}
          onCancel={this.closeModal}
          onCreate={this.handleCreate}
          cellData={cellData}
          confirmLoading={this.state.confirmLoading}
        />
      </div>
    );
  }
}

export default HubManagement;
