import { BASE_URL, API_URL } from "../constants.js";
import { Auth } from "aws-amplify";
import { generateAuthHeader } from "../authHeader";
import { notification, message } from "antd";

import axios from "axios";

import {
  requestForwarder,
  requestForwarderPost,
  requestForwarderAPI,
  requestPost,
  requestGet,
  requestDelete,
  requestPut,
} from "../newApiCallsHelper";

export const loadAllCellsViaDC = (setAllCells, setIsLoaded) => {
  return requestForwarderAPI([], `${API_URL}/grpc/v1/networktopology/cell/find?limit=99`).then(
    (result) => {
      console.log(result);
      const cells = (result || []).map((v) => {
        v.key = v.cellId;
        v.id = v.cellId;
        return v;
      });
      setIsLoaded(true);

      console.log({ cellsViaDC: cells });
      setAllCells(cells);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const loadAllCellsViaTopologyVirtual = (setAllCells, setIsLoaded, virtual) => {
  return requestForwarderAPI([], `${API_URL}/grpc/v1/networktopology/cell/find?type=${(virtual ? "VIRTUAL" : "LTE" )}&limit=99`).then(
    (result) => {
      console.log(result);
      const cells = (result || []).map((v) => {
        v.key = v.cellId;
        v.id = v.cellId;
        return v;
      }).filter(v=> (v.type == (virtual ? "VIRTUAL" : "LTE" )));
      setIsLoaded(true);

      console.log({ cellsViaDC: cells });
      setAllCells(cells);
      return cells;
    },
    (error) => {
      console.log(error);
    }
  );
};

// export const loadAllCells = (setAllCells, setIsLoaded) => {
//   return requestForwarder([], `${BASE_URL}/api/v1/scgw/cell?limit=1000`).then(
//     (result) => {
//       console.log(result);
//       const cells = result.map((v) => {
//         v.key = v.cellId;
//         v.id = v.cellId;
//         return v;
//       });
//       setIsLoaded(true);

//       console.log({ cells });
//       setAllCells(cells);
//     },
//     (error) => {
//       console.log(error);
//     }
//   );
// };

export const getCellViaTopology = (cellId, setCell) => {
  return requestGet(`${API_URL}/grpc/v1/networktopology/cell?cellId=${cellId}`)
  .then(
   (result) => {
      setCell(result.data);
      console.log(result.data);
      return result.data
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Cell Lookup",
        description: error.message,
      });
      return {}
    }
  )
};

export const deleteCellViaTopology = (cellId) => {
  return requestDelete(`${API_URL}/grpc/v1/networktopology/cell?cellId=${cellId}`)
  .then(
   (result) => {
      console.log(result.data);
      return result.data
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Cell Delete",
        description: error.message,
      });
      return {}
    }
  )
};

export const createCellViaTopology = (cellData) => {
  return requestPut(`${API_URL}/grpc/v1/networktopology/cell`, cellData)
  .then(
   (result) => {
      console.log(result.data);
      return result.data
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Cell Creation",
        description: error.message,
      });
      return {}
    }
  )
};


// export const getCell = (cellId, setCell) => {
//   return Auth.currentAuthenticatedUser()
//     .then((user) =>
//       axios.get(`${BASE_URL}/api/v1/scgw/cell/${cellId}`, {
//         ...generateAuthHeader(user),
//         params: {
//           limit: 1000,
//         },
//       })
//     )
//     .then(({ data }) => {
//       setCell(data);
//       console.log(data);
//     })
//     .catch(function (error) {
//       // handle error
//       console.log(error);
//     });
// };
