import React from "react";

import { generateAuthHeader } from "../authHeader";
import { Auth } from "aws-amplify";
import {
  MinusCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import {
  Table,
  Row,
  Popconfirm,
  Button,
  Input,
  Radio,
  Modal,
  InputNumber,
  notification,
  message,
  Descriptions,
  Popover,
  Select,
  Col,
  Result,
  Tag,
} from "antd";
import Text from "antd/lib/typography/Text";

import { BASE_URL, REACT_APP_CPE_TYPE } from "../constants.js";
import { getColumnSearchProps } from "../SubscriberManagement/search";
import { withRouter } from "react-router-dom";
import { getCPEMappingNew, postCPEMappingNew, postTopologyMapping } from "./newApiCalls";
import { getCellViaTopology } from "../Shared/cellCallsViaDC";

const { Option } = Select;

const axios = require("axios");

const CPECreateForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        confirmLoading,
        cellId,
        allCPEs,
      } = this.props;
      const { getFieldDecorator } = form;

      return (
        <Modal
          visible={visible}
          title="Move a Hub to this Cell"
          okText="Move"
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={confirmLoading}
        >
          <Form layout="vertical">
            <Form.Item label="Cell ID">
              {getFieldDecorator("cellId", {
                initialValue: cellId,
                rules: [
                  {
                    required: true,
                    message: "Please input the Cell ID!",
                  },
                ],
              })(<Input disabled />)}
            </Form.Item>
            <Form.Item label="Hub ID">
              {getFieldDecorator("hubId")(
                <Select showSearch>
                  {allCPEs &&
                    allCPEs.map((v) => (
                      <Option key={v.id} value={v.hubId}>
                        {v.hubId}
                      </Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

const hubColumns = [
  {
    key: "hubId",
    dataIndex: "hubId",
    title: "Hub Id",
  },
  {
    key: "accountid",
    dataIndex: "accountid",
    title: "Account ID",
  },
];

// const emptyCPEInfo = (cpe, deleteCPE, reload) => (
//   <Col style={{ maxWidth: "300px" }}>
//     <Row>
//       <Text>This CPE has no hubs assosiated with it.</Text>
//     </Row>
//     <Row>
//       <Text>Would you like to permanantly delete this CPE?</Text>
//     </Row>
//     <Row align="center">
//       <Button
//         onClick={() => deleteCPE(cpe.id).then(() => reload())}
//         size="small"
//         type="danger"
//       >
//         Delete
//       </Button>
//     </Row>
//   </Col>
// );

class Cell extends React.Component {
  state = {
    confirmLoading: false,
    layer: false,
    cellData: [],
    allCPEs: [],
    paging: { cursors: { before: "", after: "" } },
    isLoaded: false,
    searchText: "",
    searchInput: "",
    searchedColumn: "",
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { cellId } = this.props.match.params;
    console.log(cellId)
    const setAllCPEs = (allCPEs) => {
      this.setState({
        allCPEs: allCPEs,
      });
    };

    const setCellData = (cellData) => {
      this.setState({
        cellData: cellData,
      });
    };

    const setIsLoaded = () => {
      this.setState({
        isLoaded: true,
      });
    };

    getCellViaTopology(cellId, setCellData).then((v) => {
      if (!v.cellId) {
        notification["error"]({
          message: "Cell ID Not Found",
          description: "Please Check Cell ID.",
        });
        return;
      }
      getCPEMappingNew(setAllCPEs, setIsLoaded);
    });
  };

  moveCPE = (values) => {
    console.log({ values });

    const { allCPEs } = this.state;
    // const cpe = allCPEs.filter((cpe) => cpe.hubId == values.hubId)[0];
    // console.log({ cpe });

    return postTopologyMapping(values.cellId, values.hubId)

    // return postCPEMappingNew({ ...cpe, cellId: values.cellId })
    //   .then((response) => {
    //     console.log(response);
    //     message.success("CPE Moved");
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     notification["error"]({
    //       message: "Error on CPE Move",
    //       description: "Something went wrong",
    //     });
    //     console.log(error);
    //   });
    // return Auth.currentAuthenticatedUser()
    //   .then(user =>
    //     axios.post(
    //       `${BASE_URL}/api/v1/scgw/mno/cpedata`,
    //       {
    //         data: [
    //           {
    //             ...values,
    //             ipaddress: `${values.ecgi}-${Date.now()}`,
    //           },
    //         ],
    //       },
    //       {
    //         ...generateAuthHeader(user),
    //       }
    //     )
    //   )
    //   .then(response => {
    //     console.log(response);
    //     message.success('CPE Moved');
    //   })
    //   .catch(function(error) {
    //     // handle error
    //     notification['error']({
    //       message: 'Error on CPE Move',
    //       description: 'Something went wrong',
    //     });
    //     console.log(error);
    //   });
  };

  // reMoveCPE = (cpe) => {
  //   return Auth.currentAuthenticatedUser()
  //     .then((user) =>
  //       axios.post(
  //         `${BASE_URL}/api/v1/scgw/mno/cpedata`,
  //         {
  //           data: [
  //             {
  //               ecgi: "9900000000700",
  //               ...(REACT_APP_CPE_TYPE == "imei"
  //                 ? { imei: cpe.IMEISV }
  //                 : { imsi: cpe.IMSI }),
  //               ipaddress: `${"9900000000700"}-${Date.now()}`,
  //             },
  //           ],
  //         },
  //         {
  //           ...generateAuthHeader(user),
  //         }
  //       )
  //     )
  //     .then((response) => {
  //       console.log(response);
  //       message.success("CPE Returned to Staging Cell");
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       notification["error"]({
  //         message: "Error on CPE Move",
  //         description: "Something went wrong",
  //       });
  //       console.log(error);
  //     });
  // };

  deleteCPE = (cpeId) => {
    return Auth.currentAuthenticatedUser()
      .then((user) =>
        axios.delete(
          `${BASE_URL}/api/v1/scgw/ue/` + encodeURIComponent(cpeId),
          {
            ...generateAuthHeader(user),
          }
        )
      )
      .then((response) => {
        message.success("CPE Deleted");
        console.log(response);
      })
      .catch(function (error) {
        notification["error"]({
          message: "Error on CPE Deletion",
          description: "Something went wrong",
        });
        console.log(error);
      });
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  closeModal = () => {
    this.setState({ layer: false });
  };

  handleCreate = () => {
    this.setState({ confirmLoading: true });
    const { form } = this.formRef.props;
    form.validateFields((err, values) => {
      if (err) {
        this.setState({ confirmLoading: false });
        return;
      }
      this.moveCPE(values).then((v) => {
        this.load();
      });
      console.log("Received values of form: ", values);
      form.resetFields();
      this.setState({ confirmLoading: false, layer: false });
    });
  };

  setSearchText = (text) => {
    this.setState({ searchText: text });
  };

  setSearchInput = (input) => {
    return (input) => this.setState({ searchInput: input });
  };

  setSearchedColumn = (col) => {
    this.setState({ searchedColumn: col });
  };

  render() {
    const { vpnStatus } = this.props;

    const {
      cellData,
      allCPEs,
      searchText,
      searchedColumn,
      searchInput,
    } = this.state;
    const { cpes, type, description, enodeb, mcc, mnc, usage } = cellData;

    const { cellId } = this.props.match.params;

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setSearchText(selectedKeys[0]);
      this.setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      this.setSearchText("");
    };

    const generateColumnSearchFunctions = (dataIndex) => ({
      dataIndex: dataIndex,
      handleSearch: handleSearch,
      handleReset: handleReset,
      searchedColumn,
      searchText,
      setSearchInput: this.setSearchInput,
      searchInput: searchInput,
    });

    const columns = [
      // {
      //   title: "LTE CPE ID",
      //   children: [
      //     {
      //       key: "imsi",
      //       dataIndex: "imsi",
      //       title: "IMSI",
      //       ...getColumnSearchProps(generateColumnSearchFunctions("imsi")),
      //       sorter: (a, b) =>
      //         a.imsi.toLowerCase() < b.imsi.toLowerCase() ? -1 : 1,
      //     },
      //     {
      //       key: "cpeIp",
      //       dataIndex: "cpeIp",
      //       title: "CPE IP",
      //     },
      //   ],
      // },
      {
        key: "hubId",
        dataIndex: "hubId",
        title: "Hub Info",
      },
      {
        key: "hubInfo",
        dataIndex: "hubId",
        title: "Hub Info",
        render: (datum) => (
          <InfoCircleOutlined
            onClick={() =>
              this.props.history.push(
                `/${this.props.debugMode ? "debug_devices" : "device_status"}/${datum}`
              )
            }
          />
        ),
      },
      // {
      //   key: "cellId",
      //   dataIndex: "cellId",
      //   title: "Cell Info",
      // },
      // {
      //   key: "attachmentState",
      //   dataIndex: "attachmentState",
      //   title: "Attachment State",
      //   filters: [
      //     { text: "Attached", value: "Attached" },
      //     { text: "Detached", value: "Detached" },
      //   ],
      //   filterMultiple: true,
      //   onFilter: (value, record) => record.attachmentState === value,
      //   render: (datum) => {
      //     const attachmentState = datum;

      //     return (
      //       attachmentState && (
      //         <Row justify="center">
      //           <Tag color={attachmentState == "Attached" ? "#87d068" : "#f50"}>
      //             {attachmentState}
      //           </Tag>
      //         </Row>
      //       )
      //     );
      //   },
      // },
      // {
      //   key: "virtual",
      //   dataIndex: "virtual",
      //   title: "Virtual",
      //   render: (datum) => String(datum),
      //   filters: [
      //     { text: "True", value: true },
      //     { text: "False", value: false },
      //   ],
      //   filterMultiple: false,
      //   onFilter: (value, record) => record.virtual === value,
      // },
    ];

    console.log({ allCPEs });
    console.log({ cellId });

    return vpnStatus ? (
      <div
        style={{
          paddingLeft: "55px",
          paddingRight: "55px",
        }}
      >
        <Row style={{ margin: "10px" }} justify="end">
          <Button
            icon={<PlusOutlined />}
            onClick={() => this.setState({ layer: true })}
          />
        </Row>
        <Row
          style={{
            margin: "10px",
          }}
        >
          <Descriptions title="Cell Info" bordered>
            <Descriptions.Item label="Cell ID">{cellId}</Descriptions.Item>
            <Descriptions.Item span={2} label="Description">
              {description || "No Description"}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              {(type)}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row
          style={{
            margin: "10px",
          }}
        >
          <Table
            columns={columns}
            defaultExpandAllRows={true}
            dataSource={allCPEs.filter((cpe) => cpe.cellId == cellId)}
            bordered
            pagination={false}
          />
        </Row>
        <CPECreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.layer}
          onCancel={this.closeModal}
          onCreate={this.handleCreate}
          confirmLoading={this.state.confirmLoading}
          cellId={cellId}
          allCPEs={allCPEs.filter((cpe) => cpe.cellId != cellId)}
        />
      </div>
    ) : (
      <Row style={{ margin: "10px" }} justify="center">
        <Result status="warning" title="Connection failed, check your VPN" />
      </Row>
    );
  }
}

export default withRouter(Cell);
