import React, { useState, useEffect } from "react";
import Logo from "./img_siden_logo.png";
import "./App.css";

import Amplify, { Auth } from "aws-amplify";
import {
  AmplifyAuthenticator,
  AmplifySignOut,
  AmplifySignIn,
} from "@aws-amplify/ui-react";

import { Layout, Menu, Dropdown, Row, Select } from "antd";
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  UploadOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import SideMenu from "./SideMenu";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import Avatar from "antd/lib/avatar/avatar";
import styles from "./header.less";

const { Header, Content, Footer, Sider } = Layout;
const { Option } = Select;

const onMenuClick = ({ key, keyPath, item, domEvent }) => {
  // const history = useHistory();

  if (key === "logout") {
    Auth.signOut();
    return;
  }

  console.log(`/account/${key}`);
  // history.push(`/account/${key}`);
};

const menuHeaderDropdown = (
  // <Menu className={styles.menu} selectedKeys={[]} >
  <Menu className={{ display: "flex" }} onClick={onMenuClick}>
    {/* {
      <Menu.Item key="center">
        <UserOutlined />
        User
      </Menu.Item>
    }
    {
      <Menu.Item key="settings">
        <SettingOutlined />
        Settings
      </Menu.Item>
    }
    {<Menu.Divider />} */}

    <Menu.Item key="logout">
      <LogoutOutlined />
      Sign Out
    </Menu.Item>
  </Menu>
);

function SidenHeader(props) {
  const [user, setUser] = useState({});
 
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => setUser(user));
  }, []);

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <Row align="middle" justify="end" style={{ paddingRight: "25px" }}>
        {props.hasSidenTokenAdminRole && <Select
          value={props.selectedMNOId}
          onChange={props.setMNOTenant}
          dropdownMatchSelectWidth={true}
          style={{ width: "210px", marginRight: "20px" }}
          defaultValue={props.selectedMNOId}
        >
          {props.partnerMNOs.map(mno => (<Option value={mno.mnoId}>{`${mno.alias}-${mno.mnoId}`}</Option>))}
          {/* <Option value="m17641935">m17641935</Option>
          <Option value="m17641936">m17641936</Option>
          <Option value="m17641937">m17641937</Option>
          <Option value="m17641938">m17641938</Option>
          <Option value="m17641939">m17641939</Option>
          <Option value="m111111">m111111</Option>
          <Option value="test_mno">test_mno</Option> */}
        </Select>}
        <Dropdown overlay={menuHeaderDropdown}>
          <span>
            {/* <span className={`${styles.action} ${styles.account}`}> */}
            <span style={{ color: "white", marginRight: "10px" }}>
              {(user && user.attributes && user.attributes.email) ||
                "User Email"}
            </span>
            <Avatar
              className={styles.avatar}
              // src={currentUser.avatar}
              alt="avatar"
              icon={<UserOutlined />}
            />
            {/* <span className={`${styles.name} anticon`}>{"nam"}</span> */}
          </span>
        </Dropdown>
      </Row>
    </Header>
  );
}

export default SidenHeader;
