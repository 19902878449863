// React
import React, { useEffect, useState } from "react";

import { generateAuthHeader, generateSidenMNOToken, parseJWT, generateSidenToken } from "../authHeader";

import { Auth } from "aws-amplify";
import { PlusOutlined } from "@ant-design/icons";

import { Row, Button, message, notification, Result , Descriptions, Spin, Popconfirm, Table, Col, Collapse, Tag, Input, Form, Radio, Space, Divider} from "antd";

import { Link } from "react-router-dom";

import moment from "moment"

import { createNewAccount, decryptSingleCellId, encryptSingleCellId, loadAllSCEConfigs, loadAllUIConfigs, loadDiskConfig} from "./newApiCalls.js";
import { useParams } from "react-router";
import Title from "antd/lib/typography/Title";

import ReactJson from 'react-json-view'
import { displayedVersion, REACT_APP_QS_HOME_PAGE } from "../constants";
import { asyncRunQueryRaw } from "../Shared/quickSight";
import Text from "antd/lib/typography/Text";

// use the component in your app!
const {Panel} = Collapse;


const axios = require("axios");

const checkVersion = () => {
    return Auth.currentAuthenticatedUser().then((user) => {
      return axios
        .get(
          `https://dashboard.stg.siden.io/version_number.txt/version_number.txt`, generateAuthHeader(user)
        )
        .then((response) => {
          const serverVersion = response.data;
          return serverVersion
        })
        .catch(() => ("Version Check Error"));
    });
  };

const yesNoTag = (boolean) => (
  boolean ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag> 
)

const DashboardConfigs = (props) => {
  const [DashboardConfigs, setDashboardConfigs] = useState([])
  const [sidenToken, setSidenToken] = useState({})
  const [sidenMNOToken, setSidenMNOToken] = useState({})
  const [serverVersion, setServerVersion] = useState("Unavailable")
  const [rawQSData, setRawQSData] = useState(null)
   const [decryptFormLoading, setDecryptFormLoading] = useState(false);
   const [encryptFormLoading, setEncryptFormLoading] = useState(false);
   const [decryptedCellId, setDecryptedCellId] = useState(null);
   const [encryptedCellId, setEncryptedCellId] = useState(null);

  const [decryptForm] = Form.useForm()
  const [encryptForm] = Form.useForm()

  const {user} = props;
  const userGroups = user ? user.signInUserSession.idToken.payload["cognito:groups"] : []

  // const [user, setUser] = useState({})
  // const [activePurchase, setActivePurchase] = useState([])
  // const [historyPurchases, setHistoryPurchases] = useState([])
  // const [catalog, setCatalog] = useState({});

// const {sidenAccountId} = useParams()

  // useEffect(() => {
    // loadAccountBySID(setAccount, sidenAccountId).then(account=>{
    //   if(account) {
    //     loadHubUser(setUser, account.hubId)
    //   }
  //   })
  // }, [])

    useEffect(() => {
      generateSidenToken().then(token=> setSidenToken(token))
    }, [])
    useEffect(() => {
      generateSidenMNOToken().then(token=> setSidenMNOToken(token))
    }, [])
    useEffect(() => {
      checkVersion().then(serverVersion=> setServerVersion(serverVersion))
    }, [])
    useEffect(() => {
      const parameters = {
        dashboardId: REACT_APP_QS_HOME_PAGE,
        dashboardRegion:  "us-east-2",
        openIdToken: user.signInUserSession.idToken.jwtToken,
        resetDisabled: true,
        undoRedoDisabled: true,
    }

      asyncRunQueryRaw(parameters).then(raw=> setRawQSData(raw))
    }, [])
  // const columns = [
  //   {
  //     key: "plan",
  //     title: "Plan",
  //     dataIndex: "plan",
  //   },
  //   {
  //     key: "name",
  //     title: "name",
  //     dataIndex: "plan",
  //     render: (datum) => catalog[datum],
  //   },
  //   {
  //     key: "isPromo",
  //     title: "isPromo?",
  //     dataIndex: "isPromo",
  //     render: (datum) => String(datum),
  //   },
  //   {
  //     key: "start",
  //     title: "Start",
  //     dataIndex: "start",
  //   },
  //   {
  //     key: "end",
  //     title: "End",
  //     dataIndex: "end",
  //   },
  // ]

  const parsedSidenToken = parseJWT(sidenToken.jwt)
  const parsedSidenMNOToken = parseJWT(sidenMNOToken.jwt)

   
  const decryptCellId = (values) => {
    console.log(values)
    setDecryptFormLoading(true)
        decryptSingleCellId(values).then(v=>{
          console.log({v})
      setDecryptFormLoading(false)
      setDecryptedCellId(v.cellId)

    })
  };
  const encryptCellId = (values) => {
    console.log(values)
    setEncryptFormLoading(true)
        encryptSingleCellId(values).then(v=>{
          console.log({v})
      setEncryptFormLoading(false)
      setEncryptedCellId(v.encryptedCellId)

    })
  };

  return (
    <Col>
       <Title level={2}>Encrypt/Decrypt Cell IDs</Title>
        <Row>
          {/* <Button type="primary" onClick={forceRefresh}>Refresh</Button> */}
        </Row>
        <Row>
          <Title level={5}>Decryption</Title>
        </Row>
        <Row>
        <Form form={decryptForm} name="decrypt-form" onFinish={decryptCellId}>
            <Form.Item name={[ 'encryptedCellId']} label="Encrypted Cell ID" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={decryptFormLoading}>
                Submit
              </Button>
            </Form.Item>
            <Text>Decrypted Cell ID: {decryptedCellId}</Text>
          </Form>
        </Row>
        <Divider/>
        <Row>
          <Title level={5}>Encryption</Title>
        </Row>
        <Row>
        <Form form={encryptForm} name="encrypt-form" onFinish={encryptCellId}>
            <Form.Item name={[ 'decryptedCellId']} label="Decrypted Cell ID" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item name={[ 'mnoId']} label="MNO ID" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={encryptFormLoading}>
                Submit
              </Button>
            </Form.Item>
            <Text>Encrypted Cell ID: {encryptedCellId}</Text>
          </Form>
        </Row>
        {/* <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>SCE Configurations</Title>
          </Row>
          {SCEConfigs.length != 0  ? (<Row>
            <Collapse defaultActiveKey={[]}>
              {SCEConfigs.map(SCEConfig => {
                return (
                <Panel 
                  style={{minWidth: "600px"}}
                  header={SCEConfig.policyId} 
                  key={SCEConfig.policyId}
                  extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={SCEConfig}  theme="hopscotch"/>
                </Panel>
                )
              })}
            </Collapse>
          </Row>) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Col>
        <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>UI Configurations</Title>
          </Row>
          {UIConfigs.length != 0 ? (<Row>
            <Collapse defaultActiveKey={[]}>
              {UIConfigs.map(SCEConfig => {
                return (
                <Panel 
                  style={{minWidth: "600px"}}
                  header={SCEConfig.policyId} 
                  key={SCEConfig.policyId}
                  extra={SCEConfig.defaultConfig && <Tag color="#87d068">Default Configuration</Tag>}
                >
                  <ReactJson src={SCEConfig}  theme="hopscotch"/>
                </Panel>
                )
              })}
            </Collapse>
          </Row>) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Col>
        <Col style={{marginTop: "20px"}}>
            
          <Row>
            <Title level={4}>Disk Configuration</Title>
          </Row>
          <Row>
          {diskConfig ? (
        <Descriptions
          bordered
          column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
          >
              <Descriptions.Item label="hubPurgeItemsBunchCount">
                {diskConfig.hubPurgeItemsBunchCount}
              </Descriptions.Item>
              <Descriptions.Item label="minDiskSpaceRatio">
                {diskConfig.minDiskSpaceRatio}
              </Descriptions.Item>
              <Descriptions.Item label="preventDeliveryCycleBufferSizeMb">
                {diskConfig.preventDeliveryCycleBufferSizeMb}
              </Descriptions.Item>
              <Descriptions.Item label="purgeRunAvailableDiskSpaceSizeMb">
                {diskConfig.purgeRunAvailableDiskSpaceSizeMb}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Row>
        </Col> */}
        </Col>
      )
}

export default DashboardConfigs;
