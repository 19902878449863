// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";

import { Row, Spin, Divider, Menu, Typography } from "antd";

import { BASE_URL } from "../constants.js";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import Service from "./Service.js";
import DormantCapacity from "./DormantCapacity.js";
// import DeliverySchedules from "./DeliverySchedules";
// import MasterSchedule from "./MasterSchedule";
// import HubCache from "./HubCache";

const { Text } = Typography;

const ServiceManagment = (props) => {
  const { ownerData, subscriberData } = props;
  const { hubId } = useParams();

  let location = useLocation();
  let history = useHistory();

  const [user, setUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => setUser(user));
  }, []);

  const userGroups = user
    ? user.signInUserSession.idToken.payload["cognito:groups"]
    : [];

  const sidenServiceAdmin =
    (userGroups || []).includes("siden_service_admin") || false;

  console.log(userGroups);
  console.log(sidenServiceAdmin);

  console.log(location);

  // const [isMasterScheduleLoaded, setIsMasterScheduleLoaded] = useState(false);
  // const [masterSchedule, setMasterSchedule] = useState([]);

  // useEffect(() => {
  //   if (!isMasterScheduleLoaded) {
  //     getMasterScheduleForStatusAndHub(
  //       setMasterSchedule,
  //       setIsMasterScheduleLoaded,
  //       'active',
  //       hubId
  //     );
  //   }
  // }, []);

  return (
    <React.Fragment>
      <Route path="/service_controls">
        <Service
          status={props.status}
          checkHealth={props.checkHealth}
          vpnStatus={props.vpnStatus}
          sidenServiceAdmin={sidenServiceAdmin}
        />
      </Route>
      <Route path="/service_dorm_cap">
        <DormantCapacity sidenServiceAdmin={sidenServiceAdmin} />
      </Route>
    </React.Fragment>
  );
};

export default ServiceManagment;
