// React
import React from 'react';

import { generateAuthHeader } from '../authHeader';

// Grommet/UI
import { Row, Col, Popconfirm, Divider, Result, Menu } from 'antd';

import { Auth } from 'aws-amplify';

import { API_URL, BASE_URL } from '../constants.js';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { requestPost } from '../newApiCallsHelper';

const SuperBigButton = props => (
  <Popconfirm
    title={props.confirmTitle}
    onConfirm={props.onClick}
    okText="Yes"
    cancelText="No"
    disabled={props.disabled}
  >
    <div
      style={{
        backgroundColor: props.disabled ? '#C4C4C4' : '#3E7F9F',
        border: 'none',
        textAlign: 'center',
        textDecoration: 'none',
        fontSize: '18px',
        fontWeight: 600,
        cursor: 'pointer',
        borderRadius: '50%',
        height: '200px',
        width: '200px',
      }}
    >
      <Title
        color="#F2F2F2"
        level={3}
        style={{
          marginTop: 'calc(50% - 16px',
          display: 'inline-block',
          color: 'white',
        }}
      >
        {props.text}
      </Title>
    </div>
  </Popconfirm>
);

class ServiceManagement extends React.Component {
  
  changeDelivery = action => {
    return requestPost(`${API_URL}/api/v1/dc/delivery/${action}`, {}).then(
      v=> this.props.checkHealth()
    )
  };

  // changeDelivery = action => {
  //   Auth.currentAuthenticatedUser()
  //     .then(user =>
  //       fetch(`${BASE_URL}/api/v1/scgw/system/delivery/${action}`, {
  //         ...generateAuthHeader(user),
  //         body: JSON.stringify({}),
  //         method: 'POST',
  //       })
  //     )
  //     .then(res => res.json())
  //     .then(
  //       result => {
  //         console.log(result);
  //         this.props.checkHealth();
  //       },
  //       error => {
  //         console.log(error);
  //         this.props.checkHealth();
  //       }
  //     );
  // };

  render() {
    const { status, vpnStatus, sidenServiceAdmin } = this.props;

    const text =
      status.stopped == false
        ? [
            'All systems operating normally',
            'stop',
            'Pause Services',
            'Are you sure you want to pause services? This will stop all deliveries to all hubs across all the cells in the network.',
            'Pressing this button will pause all Siden services.',
            'Customers will be affected!',
          ]
        : [
            'All systems paused',
            'resume',
            'Resume Services',
            'Are you sure you want to resume services?',
            'Pressing this button will resume all Siden services.',
            'Customers will be affected!',
          ];

    return (
      <div style={{ marginTop: '24px', marginLeft: '4px', padding: '20px' }}>
        {vpnStatus ? (
          <React.Fragment>
            <Row justify="center">
              <Title>{text[0]}</Title>
            </Row>
            <Row justify="center">
              <SuperBigButton
                onClick={() => this.changeDelivery(text[1])}
                text={text[2]}
                confirmTitle={text[3]}
                disabled={!sidenServiceAdmin}
              />
            </Row>
            <Divider />
            <Row justify="center">
              <Title level={3}>{text[4]}</Title>
            </Row>
            <Row justify="center">
              <Title level={3}>{text[5]}</Title>
            </Row>
          </React.Fragment>
        ) : (
          <Row style={{ margin: '10px' }} justify="center">
            <Result
              status="warning"
              title="Connection failed, check your VPN"
            />
          </Row>
        )}
      </div>
    );
  }
}

export default ServiceManagement;
