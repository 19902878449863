import { generateAuthHeader } from '../authHeader';
import { Auth } from 'aws-amplify';
import { BASE_URL } from '../constants.js';
import { notification, message } from 'antd';

const axios = require('axios');

export const updateSubscription = (subscriptionId, newStatus) => {
  return Auth.currentAuthenticatedUser()
    .then(user =>
      axios.patch(
        `${BASE_URL}/api/v1/scgw/subscription/${subscriptionId}`,
        { status: newStatus },
        {
          ...generateAuthHeader(user),
        }
      )
    )
    .then(response => {
      message.success(
        `Subscription ${newStatus == 'active' ? 'Activated' : 'Suspended'}`
      );
      console.log(response);
    })
    .catch(function(error) {
      notification['error']({
        message: 'Error on Subscription Change',
        description: 'Something went wrong',
      });
      console.log(error);
    });
};

const deleteAccount = sidenAccountId => {
  return Auth.currentAuthenticatedUser()
    .then(user =>
      axios.delete(`${BASE_URL}/api/v1/scgw/account/${sidenAccountId}`, {
        ...generateAuthHeader(user),
      })
    )
    .then(response => {
      message.success('Account Deleted');
      console.log(response);
    })
    .catch(function(error) {
      notification['error']({
        message: 'Error on Account Deletion',
        description: 'Something went wrong',
      });
      console.log(error);
    });
};

const deleteHub = hubId => {
  return Auth.currentAuthenticatedUser()
    .then(user =>
      fetch(`${BASE_URL}/api/v1/scgw/hub/${hubId}`, {
        ...generateAuthHeader(user),
        method: 'DELETE',
      })
    )
    .then(response => {
      message.success('Hub Deleted');
      console.log(response);
    })
    .catch(function(error) {
      notification['error']({
        message: 'Error on Hub Deletion',
        description: 'Something went wrong',
      });
      console.log(error);
    });
};

const deleteSubscription = subscriptionId => {
  Auth.currentAuthenticatedUser()
    .then(user =>
      axios.delete(`${BASE_URL}/api/v1/scgw/subscription/${subscriptionId}`, {
        ...generateAuthHeader(user),
      })
    )
    .then(response => {
      message.success('Subscription Deleted');
      console.log(response);
    })
    .catch(function(error) {
      notification['error']({
        message: 'Error on Subscription Deletion',
        description: 'Something went wrong',
      });
      console.log(error);
    });
};

const deleteCPE = cpeId => {
  Auth.currentAuthenticatedUser()
    .then(user =>
      axios.delete(`${BASE_URL}/api/v1/scgw/ue/` + encodeURIComponent(cpeId), {
        ...generateAuthHeader(user),
      })
    )
    .then(response => {
      message.success('CPE Deleted');
      console.log(response);
    })
    .catch(function(error) {
      notification['error']({
        message: 'Error on CPE Deletion',
        description: 'Something went wrong',
      });
      console.log(error);
    });
};

export const deleteRecord = datum => {
  const { hubs, subscriptions, sidenAccountId } = datum;
  if (subscriptions) {
    deleteSubscription(subscriptions.ID);
  }
  if (hubs) {
    const hubId = hubs.hubId;
    const cpeId = hubs.cpeid;
    const cpeidtype = hubs.cpeidtype;
    if (cpeId && cpeidtype) {
      getCPEByType(cpeId, cpeidtype).then(({ data }) => deleteCPE(data.id));
    }
    deleteHub(hubId);
  }
  return deleteAccount(sidenAccountId);
};

export const getCPEByType = (cpeId, cpeidtype) => {
  return Auth.currentAuthenticatedUser().then(user =>
    axios.get(
      `${BASE_URL}/api/v1/scgw/ue/${cpeId}?type=${cpeidtype}`,
      generateAuthHeader(user)
    )
  );
};

export const upsertCPEByType = (cpeId, cpeidtype) => {
  return getCPEByType(cpeId, cpeidtype)
    .then(response => {
      if (response.data.id) {
        message.success('CPE Found, creating accoont...');
      }
    })
    .catch(error => {
      if (error.response.data.error.message == 'UE not found') {
        message.info('CPE not found, creating...');
        return createCPE({
          cellId: '9900000000700',
          IMSI: cpeId,
          IMEISV: cpeId,
          APN: 'apn-offline',
          IP: `${cpeId}-${Date.now()}`,
        });
      } else {
        notification['error']({
          message: 'Error on CPE Lookup',
          description: 'Something went wrong',
        });
        return error;
      }
    });
};

const createHub = hubInfo => {
  return Auth.currentAuthenticatedUser()
    .then(user =>
      axios.post(`${BASE_URL}/api/v1/scgw/hub`, hubInfo, {
        ...generateAuthHeader(user),
      })
    )
    .then(response => {
      console.log(response);
      message.success('Hub Created');
    })
    .catch(error => {
      const message =
        error.message == 'Request failed with status code 409'
          ? 'Hub already exists'
          : error.message;

      notification['error']({
        message: 'Error on Hub Creation',
        description: message,
      });
    });
};

const upsertSubscriptionOnEdit = (promise, record, row) => {
  const {
    subscriptions: { Status },
  } = row;

  const returnPromise = promise ? promise : Promise.resolve(42);

  return returnPromise.then(() => {
    if (!record.subscriptions) {
      const subscriptionData = {
        type: 'up',
        status: Status,
        accountId: record.sidenAccountId,
      };
      // createSubscription(subscriptionData);
    } else if (Status != record.subscriptions.Status) {
      updateSubscription(record.subscriptions.ID, Status);
    }
  });
};

export const editRecord = (record, row) => {
  console.log(record);
  console.log(row);

  const {
    hubs: { cpeidtype, cpeid, hubId },
    subscriptions: { Status },
  } = row;

  let promise = Promise.resolve(42);

  if (
    record.hubs &&
    record.hubs.cpeid == cpeid &&
    record.hubs.cpeidtype == cpeidtype &&
    hubId == record.hubs.hubId &&
    record.subscriptions &&
    record.subscriptions.Status == Status
  ) {
    // Noting Changes
    return promise;
  }

  if (
    record.hubs &&
    record.hubs.cpeid == cpeid &&
    record.hubs.cpeidtype == cpeidtype &&
    hubId == record.hubs.hubId
  ) {
    // only subscription status changes
    return upsertSubscriptionOnEdit(promise, record, row);
  }
  // So this far means that either HUB ID CHANGED, or CPEID or CPEID TYPE,
  const hubInfo = {
    accountId: record.sidenAccountId,
    hubId: hubId,
    cpeId: cpeid,
    cpeIdType: cpeidtype,
  };

  if (!record.hubs) {
    // means that there wasn't a hub to begin with.
    return promise
      .then(() => upsertCPEByType(cpeid, cpeidtype))
      .then(() =>
        createHub(hubInfo).then(() =>
          upsertSubscriptionOnEdit(null, record, row)
        )
      );
  }

  if (record.hubs.cpeid == cpeid && record.hubs.cpeidtype == cpeidtype) {
    // if just hub Id changed, then can skip the recreation of the CPE, since the data should be good and it should already exist.
    // basically only hub id changed at this point
    return promise
      .then(() => deleteHub(record.hubs.hubId))
      .then(() => createHub(hubInfo))
      .then(() => upsertSubscriptionOnEdit(null, record, row));
  }

  // ONLY WAY TO THIS POINT IS HUB ID CHANGED WITH CPE CHANGE
  // OR PURE CPE CHANGE

  return (
    promise
      // ID RATHER ORPHAN CPES then have replication error
      // .then(() =>
      //   getCPEByType(record.hubs.cpeid, record.hubs.cpeidtype)
      //     .then(({ data }) => deleteCPE(data.id))
      //     .catch(console.log('CPE NOT FOUND, Proceeding anyway'))
      // )
      .then(() => upsertCPEByType(cpeid, cpeidtype))
      .then(() => deleteHub(record.hubs.hubId))
      .then(() => createHub(hubInfo))
      .then(() => upsertSubscriptionOnEdit(null, record, row))
  );

  // if (
  //   !record.hubs ||
  //   cpeid != record.hubs.cpeid ||
  //   cpeidtype != record.hubs.cpeidtype
  // ) {
  //   if (record.hubs && record.hubs.cpeid && record.hubs.cpeidtype) {
  //     promise = promise.then(() =>
  //       getCPEByType(record.hubs.cpeid, record.hubs.cpeidtype)
  //         .then(({ data }) => deleteCPE(data.id))
  //         .catch(console.log('CPE NOT FOUND, Proceeding anyway'))
  //     );
  //   }
  //   promise = promise.then(() => upsertCPEByType(cpeid, cpeidtype));
  // }
  // promise = promise
  //   .then(() => deleteHub(record.hubs.hubId))
  //   .then(() => createHub(hubInfo))
  //   .then(() => upsertSubscriptionOnEdit(null, record, row));

  // return promise;
};

export const createCPE = values => {
  return Auth.currentAuthenticatedUser()
    .then(user =>
      axios.post(`${BASE_URL}/api/v1/scgw/ue`, values, {
        ...generateAuthHeader(user),
      })
    )
    .then(response => {
      console.log(response);
      message.success('CPE Created');
    })
    .catch(function(error) {
      notification['error']({
        message: 'Error on CPE Creation',
        description: 'Something went wrong',
      });
      console.log(error);
    });
};
