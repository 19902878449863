import { BASE_URL, API_URL } from "../constants.js";
import { Auth } from "aws-amplify";
import { generateAuthHeader } from "../authHeader";
import { notification, message } from "antd";

import {
  requestForwarder,
  requestForwarderAPI,
  requestForwarderPost,
  requestPost,
} from "../newApiCallsHelper";

const axios = require("axios");

export const getCPEMappingNew = (setCPEs, setIsLoaded, cellId) => {
  const additionalQueryString = cellId ? `&cellId=${cellId}` : "";

  return requestForwarderAPI(
    [],
    `${API_URL}/grpc/v1/networktopology/hub/cell?limit=99` + additionalQueryString
  ).then(
    (result) => {
      console.log({ result });
      const cpes = result.map((v) => {
        v.key = v.hubId;
        v.id = v.hubId;
        return v;
      });
      setIsLoaded(true);

      console.log({ cpes });
      setCPEs(cpes);
      return cpes
    },
    (error) => {
      console.log(error);
    }
  );
};

export const postCPEMappingNew = ({ cpeIp, cellId, imsi, virtual }) => {
  const postData = { data: [{ cpeIp, cellId, imsi, virtual }] };

  return Auth.currentAuthenticatedUser()
    .then((user) =>
      axios.post(
        `${BASE_URL}/grpc/v1/scgw/cpe/mapping`,
        postData,
        generateAuthHeader(user)
      )
    )
    .then(
      (result) => {
        console.log({ result });
        return result;
      },
      (error) => {
        console.log(error);
      }
    );
};

export const postTopologyMapping = (cellId, hubId) => {
  return requestPost(`${API_URL}/grpc/v1/networktopology/hub/cell`, {hubId, cellId})
    .then(
      (result) => {
        message.success(`Hub Added to Cell ${cellId}`);
        return result;
      },
      (error) => {
        console.log(error);
         notification['error']({
          message: 'Error on Topology',
          description: 'Something went wrong',
        });
      }
    );
};



// export const postCPEMappingNew = ({ cpeIp, cellId, imsi, virtual }) => {
//   const postData = { data: [{ cpeIp, cellId, imsi, virtual }] };

//   return Auth.currentAuthenticatedUser()
//     .then((user) =>
//       axios.post(
//         `${BASE_URL}/grpc/v1/scgw/cpe/mapping`,
//         postData,
//         generateAuthHeader(user)
//       )
//     )
//     .then(
//       (result) => {
//         console.log({ result });
//         return result;
//       },
//       (error) => {
//         console.log(error);
//       }
//     );
// };

export const createAccountNew = (
  newAccountInfo,
  onAccountCreationSuccess,
  onAccountCreationFailure
) => {
  return requestPost(
    `${API_URL}/grpc/v1/accounts/hubs?limit=1000`,
    newAccountInfo
  ).then(
    (result) => {
      console.log({ result });
      const { sidenAccountId } = result.data;

      notification["success"]({
        message: "New Account Created",
        description: `New Account Created with Siden Account ID ${sidenAccountId}`,
      });
      console.log(result);
      onAccountCreationSuccess(sidenAccountId);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Creation",
        description: error.response.data.message,
      });
      onAccountCreationFailure();
    }
  );
};

export const changeAccountStatus = (desiredAction, mnoAccountId) => {
  return requestPost(`${BASE_URL}/grpc/v1/scgw/account/${desiredAction}`, {
    mnoAccountId,
  }).then(
    (result) => {
      console.log({ result });
      message.success(`Account ${desiredAction}d`);
      console.log(result);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Status Change",
        description: error.response.data.message,
      });
    }
  );
};
export const updateHubLocation = (mnoAccountId, hubId, imsi) => {
  return requestPost(`${BASE_URL}/grpc/v1/scgw/account/location`, {
    mnoAccountId,
    hubId,
    imsi,
  }).then(
    (result) => {
      console.log({ result });
      message.success(`Hub Updated`);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Hub Location Update",
        description: error.response.data.message,
      });
    }
  );
};

// old UE
// : {id: "fakeImsi4", cellId: "00101012d687", IMSI: "fakeImsi4", IMEISV: "", APN: "", IP: "192.168.1.104"}
// APN: ""
// IMEISV: ""
// IMSI: "fakeImsi4"
// IP: "192.168.1.104"
// cellId: "00101012d687"
// id: "fakeImsi4"
// 5: {id: "fakeImsi5", cellId: "00101012d687", IMSI: "fakeImsi5", IMEISV: "", APN: "", IP: "192.168.1.105"}
// 6: {id: "V-AV-001", cellId: "9900000000700", IMSI: "V-AV-001", IMEISV: "", APN: "",…}
// 7: {id: "V-AK-001", cellId: "9900000000700", IMSI: "V-AK-001", IMEISV: "", APN: "",…}
// APN: ""
// IMEISV: ""
// IMSI: "V-AK-001"
// IP: "V-AK-001-1592814559886"
// cellId: "9900000000700"
// id: "V-AK-001"

// GET CPE MAPPING

// attachmentState: 'Attached';
// cellId: '00101012d687';
// cpeIp: '192.168.1.102';
// hubId: 'WH2206C87351B187';
// imsi: 'fakeImsi2';
// virtual: true;

// 24:
// attachmentState: "Detached"
// cellId: "00101012d687"
// cpeIp: "192.168.2.10"
// hubId: ""
// imsi: "3578930800010800"
// virtual: false
// __proto__: Object
// 25:
// attachmentState: "Attached"
// cellId: "9900000000700"
// cpeIp: "192.44.44.44"
// hubId: "WH220485A45DF0AF"
// imsi: "fakeOleksiiIMSI"
// virtual: true
