import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio } from 'antd';
import { REACT_APP_QS_PRB_REPORT } from '../constants';

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface HubEditFormProps {
  visible: boolean;
  onEdit: (values: Values) => void;
  onCancel: () => void;
}

const HubEditForm: React.FC<HubEditFormProps> = ({
  visible,
  onEdit,
  onCancel,
  account,
  debugMode,
}) => {
  const [form] = Form.useForm();

  console.log({account})
  return (
    <Modal
      visible={visible}
      title="Change Hub"
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onEdit(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          ...account,
          newImei: account.imei
        }}
      >
        <Form.Item 
          name="sidenAccountId" 
          label="Siden Account Id" 
          hidden
          rules={[
            {
              // required: true
              message: 'Please input the imsi',
            },
        ]}>
            <Input />
        </Form.Item>
        <Form.Item 
            name="newHubId" 
            label="New Hub ID" 
            rules={[
                  {
                    required: true,
                    message: 'Please input the Hub ID',
                  },
                ]}>
                <Input />
            </Form.Item>
        <Form.Item 
                name="newImei" 
                label="New IMEI" 
                rules={[
                  {
                    // required: true
                    message: 'Please input the IMEI',
                  },
                ]}>
            <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

// const  = () => {
//   const [visible, setVisible] = useState(false);

//   const onEdit = (values: any) => {
//     console.log('Received values of form: ', values);
//     setVisible(false);
//   };

//   return (
//     <div>
//       <Button
//         type="primary"
//         onClick={() => {
//           setVisible(true);
//         }}
//       >
//         New Collection
//       </Button>
//       <HubEditForm
//         visible={visible}
//         onEdit={onEdit}
//         onCancel={() => {
//           setVisible(false);
//         }}
//       />
//     </div>
//   );
// };

export default HubEditForm;