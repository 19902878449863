import React, { useState } from "react";
import {
  Table,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Tag,
  Row,
  Col,
  Select,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { getColumnSearchProps } from "./search.js";
import { updateSubscription, deleteRecord, editRecord } from "./apiCalls.js";
import { newDeleteRecord, newEditRecord } from "./newApiCalls.js";
import {
  MinusCircleOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useHistory, withRouter } from "react-router";

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode;
  // if (dataIndex && dataIndex[1] === 'cpeidtype') {
  //   inputNode = (
  //     <Select>
  //       <Option value="imei">IMEI</Option>
  //       <Option value="imsi">IMSI</Option>
  //     </Select>
  //   );
  // } else
  if (dataIndex === "accountStatus") {
    inputNode = (
      <Select>
        <Option value="Active">Active</Option>
        <Option value="Suspended">Suspended</Option>
        <Option value="Terminated">Terminated</Option>
      </Select>
    );
  } else {
    inputNode = <Input />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const SubscriberTable = (props) => {
  console.log(process.env);

  const [form] = Form.useForm();
  const { subscriberData } = props;
    const history = useHistory();

  const [editingKey, setEditingKey] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const generateColumnSearchFunctions = (dataIndex) => ({
    dataIndex: dataIndex,
    handleSearch: handleSearch,
    handleReset: handleReset,
    searchedColumn,
    searchText,
    setSearchInput: setSearchInput,
    searchInput: searchInput,
  });

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      newEditRecord(record, row).then(props.reloadData);
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      key: "hubId",
      title: "Hub ID",
      dataIndex: "hubId",
              fixed: "left",
      // ...getColumnSearchProps(generateColumnSearchFunctions("hubId")),
      editable: false,
    },
    {
      key: "mnoAccountId",
      dataIndex: "mnoAccountId",
      title: "MNO Account ID (MSISDN)",
                    fixed: "left",
      // ...getColumnSearchProps(generateColumnSearchFunctions("mnoAccountId")),
    },
        {
      key: "iccid",
      title: "ICCID",
      dataIndex: "iccid",
      // ...getColumnSearchProps(generateColumnSearchFunctions("imsi")),
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      fixed: "right",
      editable: true,
      width: 100,
      filters: [
        { text: "Active", value: "ACTIVE" },
        { text: "Inactive", value: "INACTIVE" },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.status === value,
      render: (datum) => {
        const status = datum;

        return (
          status && (
            <Row justify="center">
              <Tag color={status == "ACTIVE" ? "#87d068" : "#f50"}>
                {status}
              </Tag>
            </Row>
          )
        );
      },
    },
          {
        key: "info",
        title: "Info",
        fixed: "right",
        width: 60,
        render: (datum) => (
          <InfoCircleOutlined
            onClick={() =>
              history.push(
                `/${props.debugMode ? "debug_devices" : "device_status"}/${datum.hubId}`
              )
            }
          />
        ),
      },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        // inputType: ['cpeidtype', 'Status'].includes(col.dataIndex)
        inputType: "accountStatus" == col.dataIndex ? col.dataIndex : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        columns={mergedColumns}
        dataSource={subscriberData}
        bordered
        rowClassName="editable-row"
        footer={props.footer}
        pagination={false}
      />
    </Form>
  );
};

export default SubscriberTable;
