// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import moment from "moment";
import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Progress,
  Col,
  Button,
  Descriptions,
  Tag,
  Form,
  Popconfirm,
  Input,
  InputNumber,
} from "antd";

import { BASE_URL } from "../constants.js";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { getRLTEThresholdLower, getRLTEThresholdUpper, getTLTEThreshold, getRTLEPolicy, getPRBInterval, updateRLTEThresholdUpper, updateRLTEThresholdLower, updateTLTEThreshold} from "./newApiCalls.js";

const { Text, Title } = Typography;

const DormantCapacity = (props) => {
  // let location = useLocation();
  // let history = useHistory();
  // let match = useRouteMatch("/service_management/:service");

  // console.log(match);

  // console.log(location);

    const [user, setUser] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => setUser(user));
  }, []);

  const userGroups = user
    ? user.signInUserSession.idToken.payload["cognito:groups"]
    : [];

  const sidenServiceAdmin =
    (userGroups || []).includes("siden_service_admin") || false;

  console.log(userGroups);
  console.log(sidenServiceAdmin);

  const { cellId } = props;

  const [isGlobalDataLoaded, setIsGlobalDataLoaded] = useState(false);
  const [globalData, setGlobalData] = useState({});
  const [TLTEThreshold, setTLTEThreshold] = useState(null);
  const [RLTEThresholdLower, setRLTEThresholdLower] = useState(null);
  const [RLTEThresholdUpper, setRLTEThresholdUpper] = useState(null);
  const [RTLEPolicy, setRTLEPolicy] = useState({});
  const [PRBInterval, setPRBInterval] = useState(null);

  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const editRecord = (v) => console.log(v);

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      console.log({ row });
      if (editingKey == "rlteThresholdLower") {
        updateRLTEThresholdLower(cellId, row[editingKey]).then(()=>getRLTEThresholdLower(cellId, setRLTEThresholdLower));
      }
      if (editingKey == "rlteThresholdUpper") {
        updateRLTEThresholdUpper(cellId, row[editingKey]).then(()=>getRLTEThresholdUpper(cellId, setRLTEThresholdUpper));
      }
      if (editingKey == "tlteThreshold") {
        updateTLTEThreshold(cellId, row[editingKey]).then(()=>getTLTEThreshold(cellId, setTLTEThreshold));
      }
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const reloadData = (editingKey) => {
    // getGlobalData(setGlobalData, setIsGlobalDataLoaded);
  };

  const [layer, setLayer] = useState(false);

  useEffect(() => {
    if (!isGlobalDataLoaded) {
      // getGlobalData(setGlobalData, setIsGlobalDataLoaded);
    }
  }, []);

  useEffect(()=> {
    getTLTEThreshold(cellId, setTLTEThreshold)
  }, [cellId])
  useEffect(()=> {
    getRLTEThresholdLower(cellId, setRLTEThresholdLower)
  }, [cellId])
  useEffect(()=> {
    getRLTEThresholdUpper(cellId, setRLTEThresholdUpper)
  }, [cellId])
  useEffect(()=> {
    getRTLEPolicy(cellId, setRTLEPolicy)
  }, [cellId])
  useEffect(()=> {
    getPRBInterval(cellId, setPRBInterval)
  }, [cellId])
  console.log({ TLTEThreshold });
  console.log({ RLTEThresholdLower });
  console.log({ RLTEThresholdUpper });
  console.log({ RTLEPolicy });

    // Estimate Time Zone
  const IANAtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      {/* <Row align="center">
        <Spin size="large" />
      </Row> */}
      <Row>
    <Title level={4}>Cell ID: {cellId}</Title>
      </Row>
      <Row>
        <Title level={5}>Dormant Capacity Control Parameters</Title>
      </Row>
      <Row justify="center" gutter={16} style={{ maxWidth: "1000px" }}>
        <Col span={4}>
          <Row align="center">
            <Text>RLTE Treshold Lower</Text>
          </Row>
          <br />
          <Row align="center">
            {RLTEThresholdLower == 0 || RLTEThresholdLower ? (
              <Progress
                type="dashboard"
                percent={RLTEThresholdLower}
              />
            ) : (
              <Spin size="large" />
            )}
            {isEditing("rlteThresholdLower") && (
              <Form form={form} component={false}>
                <Form.Item
                  name={"rlteThresholdLower"}
                  rules={[
                    {
                      required: true,
                      message: `Please Input rltethreshold!`,
                    },
                  ]}
                >
                  <InputNumber min={0} max={100} />
                </Form.Item>
              </Form>
            )}
            {isEditing("rlteThresholdLower") ? (
              <Col>
                <Row
                  style={{
                    marginBottom: 8,
                  }}
                  justify="center"
                >
                  <Popconfirm
                    title="Sure you want to update RLTE?"
                    onConfirm={save}
                    disabled={!sidenServiceAdmin}
                  >
                    <Tag color="green">Save</Tag>
                  </Popconfirm>
                </Row>
                <Row justify="center">
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Tag color="red">Cancel</Tag>
                  </Popconfirm>
                </Row>
              </Col>
            ) : (
              <Tag
                color={sidenServiceAdmin ? "blue" : "#C4C4C4"}
                onClick={() =>
                  sidenServiceAdmin
                    ? setEditingKey("rlteThresholdLower")
                    : console.log("You dont have edit privileges")
                }
              >
                Edit
              </Tag>
            )}
          </Row>
        </Col>
        <Col span={2} />
        <Col span={4}>
          <Row align="center">
            <Text>RLTE Treshold Upper</Text>
          </Row>
          <br />
          <Row align="center">
            {RLTEThresholdUpper ? (
              <Progress
                type="dashboard"
                percent={RLTEThresholdUpper}
              />
            ) : (
              <Spin size="large" />
            )}
            {isEditing("rlteThresholdUpper") && (
              <Form form={form} component={false}>
                <Form.Item
                  name={"rlteThresholdUpper"}
                  rules={[
                    {
                      required: true,
                      message: `Please Input rlteThresholdUpper!`,
                    },
                  ]}
                >
                  <InputNumber min={0} max={100} />
                </Form.Item>
              </Form>
            )}
            {isEditing("rlteThresholdUpper") ? (
              <Col>
                <Row
                  style={{
                    marginBottom: 8,
                  }}
                  justify="center"
                >
                  <Popconfirm
                    title="Sure you want to update RLTE Upper?"
                    onConfirm={save}
                    disabled={!sidenServiceAdmin}
                  >
                    <Tag color="green">Save</Tag>
                  </Popconfirm>
                </Row>
                <Row justify="center">
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Tag color="red">Cancel</Tag>
                  </Popconfirm>
                </Row>
              </Col>
            ) : (
              <Tag
                color={sidenServiceAdmin ? "blue" : "#C4C4C4"}
                onClick={() =>
                  sidenServiceAdmin
                    ? setEditingKey("rlteThresholdUpper")
                    : console.log("You dont have edit privileges")
                }
              >
                Edit
              </Tag>
            )}
          </Row>
        </Col>
        <Col span={2} />
        <Col span={4}>
          <Row align="center">
            <Text>TLTE Target</Text>
          </Row>
          <br />
          <Row align="center">
            {TLTEThreshold ? (
              <Progress type="dashboard" percent={TLTEThreshold} />
            ) : (
              <Spin size="large" />
            )}
          </Row>{" "}
          <Row align="center">
            {isEditing("tlteThreshold") && (
              <Form form={form} component={false}>
                <Form.Item
                  name={"tlteThreshold"}
                  rules={[
                    {
                      required: true,
                      message: `Please Input tlteThreshold!`,
                    },
                  ]}
                >
                  <InputNumber min={0} max={100} />
                </Form.Item>
              </Form>
            )}
            {isEditing("tlteThreshold") ? (
              <Col>
                <Row
                  style={{
                    marginBottom: 8,
                  }}
                  justify="center"
                >
                  <Popconfirm
                    title="Sure you want to update TLTE?"
                    onConfirm={save}
                    disabled={!sidenServiceAdmin}
                  >
                    <Tag color="green">Save</Tag>
                  </Popconfirm>
                </Row>
                <Row justify="center">
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Tag color="red">Cancel</Tag>
                  </Popconfirm>
                </Row>
              </Col>
            ) : (
              <Tag
                color={sidenServiceAdmin ? "blue" : "#C4C4C4"}
                onClick={() =>
                  sidenServiceAdmin
                    ? setEditingKey("tlteThreshold")
                    : console.log("You dont have edit privileges")
                }
              >
                Edit
              </Tag>
            )}
          </Row>
        </Col>
        <Col span={2}></Col>
      </Row>
      <Divider />
      <Row>
        <Descriptions
          title="PRB Reports"
          bordered
 
        >
          {RTLEPolicy ? (
            <React.Fragment>
              <Descriptions.Item label="Reference PRB report" contentStyle={{minWidth: "150px"}}>
                {`${moment
                  .unix(RTLEPolicy.lastPrbReportTimestampSeconds)
                  .format("L HH:mm:ss Z")} (${IANAtimezone})`}
              </Descriptions.Item>
              <Descriptions.Item label="PRB Interval Duration (s):" contentStyle={{minWidth: "150px"}}>
                {PRBInterval}
              </Descriptions.Item>
              <Descriptions.Item label="qcGap PRB Interval (s):" contentStyle={{minWidth: "150px"}}>
                {RTLEPolicy.prbIntervalSeconds}
              </Descriptions.Item>
              <Descriptions.Item label="QC Gap Frequency" contentStyle={{minWidth: "150px"}}>
                {RTLEPolicy.qcGapFrequencyPrbIntervalCount}
              </Descriptions.Item>
            </React.Fragment>
          ) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Descriptions>
      </Row>
    </div>
  );
};

export default DormantCapacity;
