import { generateAuthHeader } from '../authHeader';
import { Auth } from 'aws-amplify';
import { BASE_URL } from '../constants.js';
import { notification, message } from 'antd';

const axios = require('axios');

export const getCPEByType = (cpeId, cpeidtype) => {
  return Auth.currentAuthenticatedUser().then(user =>
    axios.get(
      `${BASE_URL}/api/v1/scgw/ue/${cpeId}?type=${cpeidtype}`,
      generateAuthHeader(user)
    )
  );
};

export const notifyIfCPEFound = (cpeId, cpeidtype) => {
  return getCPEByType(cpeId, cpeidtype)
    .then(response => {
      if (response.data.id) {
        message.success('CPE Found');
        console.log(response);
      }
    })
    .catch(error => {
      if (error.response.data.error.message == 'UE not found') {
        message.warning('CPE not found');
      } else {
        notification['error']({
          message: 'Error on CPE Lookup',
          description: 'Something went wrong',
        });
        return error;
      }
    });
};

export const searchForHub = hubId => {
  return Auth.currentAuthenticatedUser().then(user =>
    axios.get(`${BASE_URL}/api/v1/scgw/hub/${hubId}`, {
      ...generateAuthHeader(user),
    })
  );
};
