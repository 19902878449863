import { BASE_URL, API_URL } from "../constants.js";
import { Auth } from "aws-amplify";
import { generateAuthHeader } from "../authHeader";
import { notification, message } from "antd";

import {
  requestForwarder,
  requestForwarderAPI,
  requestForwarderAPISimple,
  requestForwarderPost,
  requestPost,
  requestGet,
  requestDelete,
  requestDeleteWithPayload
} from "../newApiCallsHelper";

export const loadAllSCEConfigs = (setSCEConfigs) => {
  return requestGet(`${API_URL}/grpc/v1/hubconfig/sce/config/policy/all`
  ).then(
    (result) => {
      setSCEConfigs(result.data.data)
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Loading SCE Configs",
        description: ""
      });
    }
  );
};

export const loadSCEConfigForHub = (setSCEConfig, hubId) => {
  return requestGet(`${API_URL}/grpc/v1/hubconfig/sce/config/policy/hub/${hubId}`
  ).then(
    (result) => {
      setSCEConfig(result.data)
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Loading SCE Configuration for Hub",
        description: ""
      });
    }
  );
};

export const mapSCEConfigForHub = (hubId, policyId) => {
  return requestPost(`${API_URL}/grpc/v1/hubconfig/sce/config/mapping`, 
    {hubId, policyId}
  ).then(
    (result) => {
     message.success(`SCE Policy Updated`);
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Setting SCE Configuration for Hub",
        description: ""
      });
    }
  );
};

export const deleteSCEConfigForHub = (hubId) => {
  return requestDeleteWithPayload(`${API_URL}/grpc/v1/hubconfig/sce/config/mapping`, 
    {hubId}
  ).then(
    (result) => {
     message.success(`Default SCE Policy Restored`);
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Restoring to Default SCE Configuration for Hub",
        description: ""
      });
    }
  );
};

export const loadAllUIConfigs = (setUIConfigs) => {
  return requestGet(`${API_URL}/grpc/v1/hubconfig/ui/config/policy/all`
  ).then(
    (result) => {
      setUIConfigs(result.data.data)
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Loading Hub UI Configs",
        description: ""
      });
    }
  );
};

export const loadUIConfigForHub = (setUIConfig, hubId) => {
  return requestGet(`${API_URL}/grpc/v1/hubconfig/ui/config/policy/hub/${hubId}`
  ).then(
    (result) => {
      setUIConfig(result.data)
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Loading UI Configuration for Hub",
        description: ""
      });
    }
  );
};

export const mapUIConfigForHub = (hubId, policyId) => {
  return requestPost(`${API_URL}/grpc/v1/hubconfig/ui/config/mapping`, 
    {hubId, policyId}
  ).then(
    (result) => {
     message.success(`UI Policy Updated`);
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Setting UI Configuration for Hub",
        description: ""
      });
    }
  );
};

export const deleteUIConfigForHub = (hubId) => {
  return requestDeleteWithPayload(`${API_URL}/grpc/v1/hubconfig/ui/config/mapping`, 
    {hubId}
  ).then(
    (result) => {
     message.success(`Default UI Policy Restored`);
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Restoring to Default UI Configuration for Hub",
        description: ""
      });
    }
  );
};

export const loadDiskConfig = (setDiskConfig) => {
  return requestGet(`${API_URL}/grpc/v1/hubconfig/diskspace/config`
  ).then(
    (result) => {
      setDiskConfig(result.data)
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Loading Hub Disk Configuration",
        description: ""
      });
    }
  );
};
