require("dotenv").config();

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_API_URL;

export const HEALTH_URL = process.env.REACT_APP_HEALTH_URL;

export const REACT_APP_CPE_TYPE = process.env.REACT_APP_CPE_TYPE;
export const REACT_APP_SCE = process.env.REACT_APP_SCE;

export const DEBUG_DASHBOARD_GRAFANA =
  process.env.REACT_APP_DEBUG_DASHBOARD_GRAFANA;

export const CLOUD_MANAGEMENT_GRAFANA =
  process.env.REACT_APP_CLOUD_MANAGEMENT_GRAFANA;

export const AUTH_REDIRECT_URL =
  process.env.REACT_APP_AUTH_REDIRECT_URL

export const BUILD_ENV =
  process.env.REACT_APP_BUILD_ENV

// import HubQSDashboard from "./HubQSDashboard";
// import CellUsageQSDashboard from "./CellUsageQSDashboard";
// import CellSummaryQSDashboard from "./CellSummaryQSDashboard";
// import ContentDeliveryQSDashboard from "./ContentDeliveryQSDashboard";
// import ServiceOperationQSDashboard from "./ServiceOperationQSDashboard";
// import ContentEngagementQSDashboard from "./ContentEngagementQSDashboard";
// import PRBReportQSDashboard from "./PRBReportQSDashboard";

export const REACT_APP_QS_CELL_SUMMARY = process.env.REACT_APP_QS_CELL_SUMMARY;
export const REACT_APP_QS_CELL_USAGE = process.env.REACT_APP_QS_CELL_USAGE;
export const REACT_APP_QS_CONTENT_DELIVERY = process.env.REACT_APP_QS_CONTENT_DELIVERY;
export const REACT_APP_QS_CONTENT_ENGAGEMENT = process.env.REACT_APP_QS_CONTENT_ENGAGEMENT;
export const REACT_APP_QS_HOME_PAGE = process.env.REACT_APP_QS_HOME_PAGE;
export const REACT_APP_QS_HUB_POPULATION = process.env.REACT_APP_QS_HUB_POPULATION;
export const REACT_APP_QS_PRB_REPORT = process.env.REACT_APP_QS_PRB_REPORT;
export const REACT_APP_QS_SERVICE_OPERATIONS = process.env.REACT_APP_QS_SERVICE_OPERATIONS;
export const REACT_APP_QS_SERVICE_PROVISIONING = process.env.REACT_APP_QS_SERVICE_PROVISIONING;
export const REACT_APP_QS_SUBSCRIBER_HUB_AND_CONTENT_ANALYTICS = process.env.REACT_APP_QS_SUBSCRIBER_HUB_AND_CONTENT_ANALYTICS;

export const displayedVersion = "MNO-0.4.8";
