import { BASE_URL, API_URL } from "../constants.js";
import { Auth } from "aws-amplify";
import { generateAuthHeader,generateOldAuthHeader } from "../authHeader";
import { notification, message } from "antd";

import {
  requestDelete,
  requestForwarder,
  requestForwarderAPI,
  requestForwarderPost,
  requestGet,
  requestPost,
  requestPut,
} from "../newApiCallsHelper";

export const loadAccountsNew = (setAccounts, setIsLoaded) => {
  return requestForwarderAPI(
    [],
    `${API_URL}/grpc/v1/accounts/hubs?limit=1000`
  ).then(
    (result) => {
      console.log(result);
      const accounts = result.map((v) => {
        v.key = v.sidenAccountId;
        v.id = v.sidenAccountId;
        return v;
      });
      setIsLoaded(true);

      console.log(accounts);
      setAccounts(accounts);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const loadAccountBySID = (setAccount, sidenAccountId) => {
  return requestForwarderAPI(
    [],
    `${API_URL}/grpc/v1/accounts/hubs?sidenAccountId=${sidenAccountId}`
  ).then(
    (result) => {
      console.log(result);
      const account = (result || []).map((v) => {
        v.key = v.sidenAccountId;
        v.id = v.sidenAccountId;
        return v;
      });

      console.log(account[0]);
      setAccount(account[0]);
      return (account[0])
    },
    (error) => {
      console.log(error);
    }
  );
};

export const loadHubUser = (setUser, hubId) => {
  return requestGet(
    `${API_URL}/grpc/v1/accounts/hubs/${hubId}/user`
  ).then(
    (result) => {
      setUser(result.data);
    },
    (error) => {
      console.log(error);
    }
  );
};

export const loadSubscription = (setSubscription, sidenAccountId) => {
  return requestGet(`${API_URL}/grpc/v1/accounts/subscription?sidenAccountId=${sidenAccountId}`
  ).then(
    (result) => {
      setSubscription(result.data)
      console.log(result.data);
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Finding Subscription",
        description: "",
      });
    }
  );
};

export const loadHistoryPurchases = (setHistoryPurchases, sidenAccountId) => {
  return requestGet(`${API_URL}/grpc/v1/accounts/product/purchase/history?sidenAccountId=${sidenAccountId}`
  ).then(
    (result) => {
      setHistoryPurchases(result.data.data)
      console.log(result.data);
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Finding History Purchases",
        description: "",
      });
    }
  );
};

export const loadActivePurchases = (setActivePurchase, sidenAccountId) => {
  return requestGet(`${API_URL}/grpc/v1/accounts/product/purchase/active?sidenAccountId=${sidenAccountId}`
  ).then(
    (result) => {
      setActivePurchase(result.data.data)
      console.log(result.data);
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Finding Active Purchases",
        description: ""
      });
    }
  );
};

export const loadCatalog = (setCatalog, sidenAccountId,setCatalogDetails) => {
  return requestGet(`${API_URL}/grpc/v1/accounts/products/catalog?sidenAccountId=${sidenAccountId}`
  ).then(
    (result) => {
      const catalog = {}
      result.data.data.forEach(product => {
        catalog[product["code"]] = product["name"]
      })
      setCatalog(catalog)
      setCatalogDetails(result.data.data)
      console.log({catalog});
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Finding Catalog",
        description: error.response.data.message,
      })
    }).catch(error=> {
        setCatalog({})
        setCatalogDetails({})
        return {}
      });
};

export const postPromoSubscription = (bundle,  sidenAccountId) => {
  return requestPost(`${API_URL}/grpc/v1/accounts/subscription/promo`, {
    planId: bundle,
    sidenAccountId,
  }).then(
    (result) => {
      message.success(`Promo was set`);
      return result;
    },
    (error) => {
      console.log(error);
      notification["error"]({
        message: "Error on setting Promo subscription",
        description: "Something went wrong",
      });
    }
  );
};

export const postProductStart = (postData) => {
  return requestPost(`${API_URL}/grpc/v1/accounts/admin/product/start`, postData).then(
    (result) => {
      message.success(`Subscription was set`);
      return result;
    },
    (error) => {
      console.log(error);
      notification["error"]({
        message: "Error on setting subscription",
        description: "Something went wrong",
      });
    }
  );
};
export const postProductEnd = (postData) => {
  return requestPost(`${API_URL}/grpc/v1/accounts/admin/product/end-up`, postData).then(
    (result) => {
      message.success(`Subscription was ended`);
      return result;
    },
    (error) => {
      console.log(error);
      notification["error"]({
        message: "Error on ending subscription",
        description: "Something went wrong",
      });
    }
  );
};

export const createAccountNew = (
  newAccountInfo,
  onAccountCreationSuccess,
  onAccountCreationFailure
) => {
  return requestPost(
    `${API_URL}/grpc/v2/accounts/hubs?limit=1000`,
    newAccountInfo
  ).then(
    (result) => {
      console.log({ result });
      const { sidenAccountId } = result.data;

      notification["success"]({
        message: "New Account Created",
        description: `New Account Created with Siden Account ID ${sidenAccountId}`,
      });
      console.log(result);
      onAccountCreationSuccess(sidenAccountId);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Creation",
        description: error.response.data.message,
      });
      onAccountCreationFailure();
    }
  );
};

export const createNewAccountService = (
  newAccountInfo,
  onAccountCreationSuccess,
  onAccountCreationFailure
) => {
  return requestPost(
    `${API_URL}/grpc/v2/accounts/hubs/${newAccountInfo.hubId}`,
    newAccountInfo
  ).then(
    (result) => {
      console.log({ result });
      const { sidenAccountId } = result.data;

      notification["success"]({
        message: "New Account Created",
        description: "",
      });
      console.log(result);
      onAccountCreationSuccess(sidenAccountId);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Creation",
        description: error.response.data.message,
      });
      onAccountCreationFailure();
    }
  );
};

export const editNewAccountService = (
  record, row
) => {
  return requestPut(
    `${API_URL}/grpc/v1/accounts/hubs/${record.hubId}`,
    row
  ).then(
    (result) => {
      console.log({ result });

      notification["success"]({
        message: "Account Edited",
        description: "",
      });
      console.log(result);
      // onAccountCreationSuccess(sidenAccountId);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Edit",
        description: error.response.data.message,
      });
      // onAccountCreationFailure();
    }
  );
};
export const changeHubNewAccountService = (
  data
) => {
  return requestPut(
    `${API_URL}/grpc/v1/accounts/hubs-by-siden-id/update-hub-id`,
    data
  ).then(
    (result) => {
      console.log({ result });

      notification["success"]({
        message: "Account Hub Changed",
        description: "",
      });
      console.log(result);
      // onAccountCreationSuccess(sidenAccountId);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Change Hub",
        description: error.response.data.message,
      });
      // onAccountCreationFailure();
    }
  );
};
export const editSidenAccount = (
  og, hubId, sidenAccountId
) => {
  return requestPut(
    `${API_URL}/grpc/v1/accounts/hubs/${og}/siden-account`,
    {hubId, sidenAccountId}
  ).then(
    (result) => {
      console.log({ result });

      notification["success"]({
        message: "Account Hub Edited",
        description: "",
      });
      console.log(result);
      // onAccountCreationSuccess(sidenAccountId);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Edit",
        description: error.response.data.message,
      });
      // onAccountCreationFailure();
    }
  );
};

export const deleteNewAccountService = (
  record
) => {
  return requestDelete(
    `${API_URL}/grpc/v1/accounts/hubs/${record.hubId}`
  ).then(
    (result) => {
      console.log({ result });


      notification["success"]({
        message: "Account Deleted",
        description: "",
      });
      console.log(result);
      // onAccountCreationSuccess(sidenAccountId);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Deletion",
        description: error.response.data.message,
      });
      // onAccountCreationFailure();
    }
  );
};

export const changeAccountStatus = (desiredAction, mnoAccountId) => {
  return requestPost(`${BASE_URL}/grpc/v1/scgw/account/${desiredAction}`, {
    mnoAccountId,
  }).then(
    (result) => {
      console.log({ result });
      message.success(`Account ${desiredAction}d`);
      console.log(result);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Account Status Change",
        description: error.response.data.message,
      });
    }
  );
};
export const updateHubLocation = (mnoAccountId, hubId, imsi) => {
  return requestPost(`${BASE_URL}/grpc/v1/scgw/account/location`, {
    mnoAccountId,
    hubId,
    imsi,
  }).then(
    (result) => {
      console.log({ result });
      message.success(`Hub Updated`);
    },
    (error) => {
      console.log({ error });
      notification["error"]({
        message: "Error on Hub Location Update",
        description: error.response.data.message,
      });
    }
  );
};

export const resumeAccount = (mnoAccountId) => {
  return changeAccountStatus("resume", mnoAccountId);
};
export const suspendAccount = (mnoAccountId) => {
  return changeAccountStatus("suspend", mnoAccountId);
};
export const terminateAccount = (mnoAccountId) => {
  return changeAccountStatus("terminate", mnoAccountId);
};

export const newDeleteRecord = (datum) => {
  const { mnoAccountId } = datum;
  console.log({ datum });
  return terminateAccount(mnoAccountId);
};

export const newEditRecord = (record, row) => {
  console.log({ record });
  console.log({ row });

  const { accountStatus, hubId, imsi } = row;

  let promise = Promise.resolve(42);

  const hubIdChanged = !(record.hubId && record.hubId == hubId);
  const imsiChanged = !(record.imsi && record.imsi == imsi);
  const accountStatusChanged = !(
    record.accountStatus && record.accountStatus == accountStatus
  );

  const newSubscriptionAction = {
    Active: "resume",
    Suspended: "suspend",
    Terminated: "terminate",
  };

  const desiredAction = newSubscriptionAction[accountStatus];

  if (!hubIdChanged && !imsiChanged && !accountStatusChanged) {
    // Noting Changes
    return promise;
  }

  if (!hubIdChanged && !imsiChanged && accountStatusChanged) {
    // only subscription status changes
    return changeAccountStatus(desiredAction, record.mnoAccountId);
  }

  if (!accountStatusChanged) {
    // Update imsi or HubID, but not status
    return updateHubLocation(record.mnoAccountId, record.hubId, imsi);
  }
  // All Changed
  return updateHubLocation(record.mnoAccountId, record.hubId, imsi).then(() =>
    changeAccountStatus(desiredAction, record.mnoAccountId)
  );
};
