import { BASE_URL, API_URL } from "../constants.js";
import { Auth } from "aws-amplify";
import { generateAuthHeader } from "../authHeader";
import { notification, message } from "antd";

import {
  requestForwarder,
  requestForwarderAPI,
  requestForwarderAPISimple,
  requestForwarderPost,
  requestPost,
  requestGet,
  requestDelete,
  requestDeleteWithPayload
} from "../newApiCallsHelper";

export const createNewAccount = (accountData) => {
  console.log({accountData})

  return requestPost(`${API_URL}/grpc/v1/accounts/users/partners`, 
    accountData
  ).then(
    (result) => {
     message.success(`Dashboard Account Created`);
    },
    (error) => {
      console.log(error);
        notification["error"]({
        message: "Error on Creating Account",
        description: ""
      });
    }
  );
};