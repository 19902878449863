import React, { useState } from 'react';
import { Input, Row, message, notification, Col } from 'antd';
import { getCPEByType, searchForHub } from './apiCalls.js';
import { getCPEMappingByHubId, getCPEMappingByCPE } from './newApiCalls.js';
import { locateHubByPromise } from '../Devices/newApiCalls.js';
import { useHistory } from 'react-router';

const { Search } = Input;

const HubSearchBar = props => {
  const { setSearchBar, loadViaParam } = props;
  const [hubId, setHubId] = useState('');
  const [IMSI, setIMSI] = useState('');
  const [sidenAccountId, setSidenAccountId] = useState('');
  const [MNOAccountId, setMNOAccountId] = useState('');

  const accountSearchByHubId = () => {
    if (!hubId) return;

    loadViaParam("hubId", hubId).then(response => {
      if (response.length == 0) {
        notification['success']({
          message: 'Hub Not Found',
          description: 'Search succeeded, but Hub not found',
        });
        setHubId('');
      }
      if (response.length >= 1) {
        props.setAccounts(response)
        setHubId('');
      }
    })
    .catch(error => {
      notification['error']({
        message: 'Error on Hub Lookup',
        description: 'Something went wrong',
      });
      return error;
    });
  };
  
  const accountSearchByIMSI= () => {
    if (!IMSI) return;

    loadViaParam("imsi", IMSI).then(response => {
      if (response.length == 0) {
        notification['success']({
          message: 'Hub Not Found',
          description: 'Search succeeded, but Hub not found',
        });
        setIMSI('');
      }
      if (response.length >= 1) {
        props.setAccounts(response)
        setIMSI('');
      }
    })
    .catch(error => {
      notification['error']({
        message: 'Error on Hub Lookup',
        description: 'Something went wrong',
      });
      return error;
    });
  };

  const accountSearchBySidenAccountId= () => {
    if (!sidenAccountId) return;

    loadViaParam("sidenAccountId", sidenAccountId).then(response => {
      if (response.length == 1) {
        notification['success']({
          message: 'Account Found',
          description: response[0].sidenAccountId,
        });
        setSidenAccountId('');
      }
      if (response.length >= 1) {
        props.setAccounts(response)
        setSidenAccountId('');
      }
    })
    .catch(error => {
      notification['error']({
        message: 'Error on Hub Lookup',
        description: 'Something went wrong',
      });
      return error;
    });
  };

  const accountSearchByMNOAccountId= () => {
    if (!MNOAccountId) return;

    loadViaParam("mnoAccountId", MNOAccountId).then(response => {
      if (response.length == 0) {
        notification['success']({
          message: 'Hub Not Found',
          description: 'Search succeeded, but Hub not found',
        });
        setMNOAccountId('');
      }
      if (response.length >= 1) {
        props.setAccounts(response)
        setMNOAccountId('');
      }
    })
    .catch(error => {
      notification['error']({
        message: 'Error on Hub Lookup',
        description: 'Something went wrong',
      });
      return error;
    });
  };

  return (
    <Col>
      <Row style={{ marginBottom: '10px' }} justify="space-between">
        <div style={{ maxWidth: '800px' }}>
          <Search
            label="Hub ID"
            placeholder="Search by Hub ID"
            onChange={e => setHubId(e.target.value)}
            value={hubId}
            onSearch={accountSearchByHubId}
            enterButton
          />
        </div>
        &nbsp; &nbsp; &nbsp;
        <div style={{ maxWidth: '800px' }}>
          <Search
            label="mnoAccountId"
            placeholder="Search by MNO Account ID"
            onChange={e => setMNOAccountId(e.target.value)}
            onSearch={accountSearchByMNOAccountId}
            value={MNOAccountId}
            enterButton
          />
        </div>
        {/* &nbsp; &nbsp; &nbsp;
        <div style={{ maxWidth: '800px' }}>
          <Search
            label="imsi"
            placeholder="Search by imsi"
            onChange={e => setIMSI(e.target.value)}
            onSearch={accountSearchByIMSI}
            value={IMSI}
            enterButton
          />
        </div> */}
        &nbsp; &nbsp; &nbsp;
        <div style={{ maxWidth: '800px' }}>
          <Search
            label="sidenAccountId"
            placeholder="Search by Siden Account ID"
            onChange={e => setSidenAccountId(e.target.value)}
            onSearch={accountSearchBySidenAccountId}
            value={sidenAccountId}
            enterButton
          />
        </div>

      </Row>
    </Col>
  );
};

export default HubSearchBar;
