// React
import React, { useState, useEffect } from "react";

import { Auth } from "aws-amplify";
import moment from "moment";
import {
  Row,
  Spin,
  Divider,
  Menu,
  Typography,
  Progress,
  Col,
  Button,
  Descriptions,
  Tag,
  Form,
  Popconfirm,
  Input,
  InputNumber,
} from "antd";

import { BASE_URL } from "../constants.js";

import {
  Route,
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { getGlobalData, updateGlobalData } from "./new_api_calls.js";

const { Text, Title } = Typography;

const DormantCapacity = (props) => {
  let location = useLocation();
  let history = useHistory();
  let match = useRouteMatch("/service_management/:service");

  console.log(match);

  console.log(location);

  const { sidenServiceAdmin } = props;

  const [isGlobalDataLoaded, setIsGlobalDataLoaded] = useState(false);
  const [globalData, setGlobalData] = useState({});

  const [form] = Form.useForm();

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const editRecord = (v) => console.log(v);

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      console.log({ row });
      if (editingKey == "rlteThresholdLower") {
        updateGlobalData(row).then(reloadData(editingKey));
      }
      if (editingKey == "rlteThresholdUpper") {
        updateGlobalData(row).then(reloadData(editingKey));
      }
      if (editingKey == "tlteThreshold") {
        updateGlobalData(row).then(() => reloadData(editingKey));
      }
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const reloadData = (editingKey) => {
    getGlobalData(setGlobalData, setIsGlobalDataLoaded);
  };

  const [layer, setLayer] = useState(false);

  useEffect(() => {
    if (!isGlobalDataLoaded) {
      getGlobalData(setGlobalData, setIsGlobalDataLoaded);
    }
  }, []);
  console.log({ globalData });


  // Estimate Time Zone
  const IANAtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      {/* <Row align="center">
        <Spin size="large" />
      </Row> */}
      <Row>
        <Title level={4}>Dormant Capacity Control Parameters</Title>
      </Row>
      <Row justify="center" gutter={16} style={{ maxWidth: "1000px" }}>
        <Col span={4}>
          <Row align="center">
            <Text>RLTE Treshold Lower</Text>
          </Row>
          <br />
          <Row align="center">
            {globalData ? (
              <Progress
                type="dashboard"
                percent={globalData.rlteThresholdLower}
              />
            ) : (
              <Spin size="large" />
            )}
            {isEditing("rlteThresholdLower") && (
              <Form form={form} component={false}>
                <Form.Item
                  name={"rlteThresholdLower"}
                  rules={[
                    {
                      required: true,
                      message: `Please Input rltethreshold!`,
                    },
                  ]}
                >
                  <InputNumber min={0} max={100} />
                </Form.Item>
              </Form>
            )}
            {isEditing("rlteThresholdLower") ? (
              <Col>
                <Row
                  style={{
                    marginBottom: 8,
                  }}
                  justify="center"
                >
                  <Popconfirm
                    title="Sure you want to update RLTE?"
                    onConfirm={save}
                    disabled={!sidenServiceAdmin}
                  >
                    <Tag color="green">Save</Tag>
                  </Popconfirm>
                </Row>
                <Row justify="center">
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Tag color="red">Cancel</Tag>
                  </Popconfirm>
                </Row>
              </Col>
            ) : (
              <Tag
                color={sidenServiceAdmin ? "blue" : "#C4C4C4"}
                onClick={() =>
                  sidenServiceAdmin
                    ? setEditingKey("rlteThresholdLower")
                    : console.log("You dont have edit privileges")
                }
              >
                Edit
              </Tag>
            )}
          </Row>
        </Col>
        <Col span={2} />
        <Col span={4}>
          <Row align="center">
            <Text>RLTE Treshold Upper</Text>
          </Row>
          <br />
          <Row align="center">
            {globalData ? (
              <Progress
                type="dashboard"
                percent={globalData.rlteThresholdUpper}
              />
            ) : (
              <Spin size="large" />
            )}
            {isEditing("rlteThresholdUpper") && (
              <Form form={form} component={false}>
                <Form.Item
                  name={"rlteThresholdUpper"}
                  rules={[
                    {
                      required: true,
                      message: `Please Input rlteThresholdUpper!`,
                    },
                  ]}
                >
                  <InputNumber min={0} max={100} />
                </Form.Item>
              </Form>
            )}
            {isEditing("rlteThresholdUpper") ? (
              <Col>
                <Row
                  style={{
                    marginBottom: 8,
                  }}
                  justify="center"
                >
                  <Popconfirm
                    title="Sure you want to update RLTE Upper?"
                    onConfirm={save}
                    disabled={!sidenServiceAdmin}
                  >
                    <Tag color="green">Save</Tag>
                  </Popconfirm>
                </Row>
                <Row justify="center">
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Tag color="red">Cancel</Tag>
                  </Popconfirm>
                </Row>
              </Col>
            ) : (
              <Tag
                color={sidenServiceAdmin ? "blue" : "#C4C4C4"}
                onClick={() =>
                  sidenServiceAdmin
                    ? setEditingKey("rlteThresholdUpper")
                    : console.log("You dont have edit privileges")
                }
              >
                Edit
              </Tag>
            )}
          </Row>
        </Col>
        <Col span={2} />
        <Col span={4}>
          <Row align="center" style={{ height: "44px" }}>
            <Text>TLTE Target</Text>
          </Row>
          <br />
          <Row align="center">
            {globalData ? (
              <Progress type="dashboard" percent={globalData.tlteThreshold} />
            ) : (
              <Spin size="large" />
            )}
          </Row>{" "}
          <Row align="center">
            {isEditing("tlteThreshold") && (
              <Form form={form} component={false}>
                <Form.Item
                  name={"tlteThreshold"}
                  rules={[
                    {
                      required: true,
                      message: `Please Input tlteThreshold!`,
                    },
                  ]}
                >
                  <InputNumber min={0} max={100} />
                </Form.Item>
              </Form>
            )}
            {isEditing("tlteThreshold") ? (
              <Col>
                <Row
                  style={{
                    marginBottom: 8,
                  }}
                  justify="center"
                >
                  <Popconfirm
                    title="Sure you want to update TLTE?"
                    onConfirm={save}
                    disabled={!sidenServiceAdmin}
                  >
                    <Tag color="green">Save</Tag>
                  </Popconfirm>
                </Row>
                <Row justify="center">
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Tag color="red">Cancel</Tag>
                  </Popconfirm>
                </Row>
              </Col>
            ) : (
              <Tag
                color={sidenServiceAdmin ? "blue" : "#C4C4C4"}
                onClick={() =>
                  sidenServiceAdmin
                    ? setEditingKey("tlteThreshold")
                    : console.log("You dont have edit privileges")
                }
              >
                Edit
              </Tag>
            )}
          </Row>
        </Col>
        <Col span={2}></Col>
        {/* <Col span={4}>
          <Row align="center">
            <Text>QC Gap Frequency</Text>
          </Row>
          <br />
          <Row align="center">
            {globalData && globalData.qcGapData ? (
              <Progress
                type="dashboard"
                percent={99.99}
                strokeColor="rgba(240, 242, 245)"
                format={(percent) =>
                  `${globalData.qcGapData.qcGapFrequencyPrbIntervalCount}   `
                }
              />
            ) : (
              <Spin size="large" />
            )}
            {isEditing("qcGapFrequency") && (
              <Form form={form} component={false}>
                <Form.Item
                  name={"qcGapFrequency"}
                  rules={[
                    {
                      required: true,
                      message: `Please Input qcGapFrequency!`,
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
              </Form>
            )}
            {isEditing("qcGapFrequency") ? (
              <Col>
                <Row
                  style={{
                    marginBottom: 8,
                  }}
                  justify="center"
                >
                  <Popconfirm
                    title="Sure you want to update QC Gap Frequency?"
                    disabled={!sidenServiceAdmin}
                    onConfirm={(v) => console.log("QC", v)}
                  >
                    <Tag color="green">Save</Tag>
                  </Popconfirm>
                </Row>
                <Row justify="center">
                  <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                    <Tag color="red">Cancel</Tag>
                  </Popconfirm>
                </Row>
              </Col>
            ) : (
              <Tag
                color={sidenServiceAdmin ? "blue" : "#C4C4C4"}
                onClick={() =>
                  sidenServiceAdmin
                    ? setEditingKey("qcGapFrequency")
                    : console.log("You dont have edit privileges")
                }
              >
                Edit
              </Tag>
            )}
          </Row>
        </Col> */}
      </Row>
      <Divider />
      <Row>
        <Descriptions
          title="PRB Reports"
          bordered
          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
        >
          {globalData && globalData.qcGapData ? (
            <React.Fragment>
              <Descriptions.Item label="Reference PRB report">
                {`${moment
                  .unix(globalData.qcGapData.lastPrbReportTimestampSeconds)
                  .format("L HH:mm:ss Z")} (${IANAtimezone})`}
              </Descriptions.Item>
              <Descriptions.Item label="PRB Interval Duration (s):">
                {globalData.prbInterval}
              </Descriptions.Item>
              <Descriptions.Item label="qcGap PRB Interval (s):">
                {globalData.qcGapData.qcGapPrbIntervalSeconds}
              </Descriptions.Item>
              <Descriptions.Item label="QC Gap Frequency">
                {globalData.qcGapData.qcGapFrequencyPrbIntervalCount}
              </Descriptions.Item>
            </React.Fragment>
          ) : (
            <Row align="center">
              <Spin size="large" />
            </Row>
          )}
        </Descriptions>
      </Row>
    </div>
  );
};

export default DormantCapacity;
