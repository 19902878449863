import React, { useState, useEffect, useContext } from "react";

import Amplify, { Auth } from "aws-amplify";

import { Layout, Menu, Dropdown, Row, Typography, Spin } from "antd";

import {apiGatewayGetDashboardEmbedUrl, asyncGetQSUrl} from "../Shared/quickSight.js"

import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { withRouter } from "react-router";
import { generateSidenMNOToken } from "../authHeader.js";
// const dashboard = embedDashboard(options);



const {Title} = Typography
const { Header, Content, Footer, Sider } = Layout;

function QSDashboardGeneric(props) {
  const [quickSight, setQuickSight] = useState(null);
  const {dashboardId, title} = props; 

  const additionalParameters = {
    ...(props.hubId ? {hubId: props.hubId} : {}) ,
    ...(props.mnoId ? {mnoId: props.mnoId} : {}) ,
  }

   useEffect(() => {
     Auth.currentAuthenticatedUser().then((user) => {
      const parameters = {
        dashboardId: dashboardId,
        dashboardRegion:  "us-east-2",
        openIdToken: user.signInUserSession.idToken.jwtToken,
        resetDisabled: true,
        undoRedoDisabled: true,
      }
      asyncGetQSUrl(parameters, setQuickSight).then(url=> {
        var containerDiv = document.getElementById("dashboardContainer")
                if (!containerDiv) {return;}
        var options = {
          url: url,
          container: containerDiv,
          parameters: {
              ...additionalParameters,
              country: 'United States'
          },
          scrolling: "no",
          height: "1000px",
          width: "100%"
        } 
       QuickSightEmbedding.embedDashboard(options)
      })
    })
  }, [dashboardId])

  return (
    <React.Fragment key={dashboardId}>
        {title && <Title level={2} >{title}</Title>}
        {quickSight ? 
        <div key={dashboardId+"a"} id={"dashboardContainer"} />
         :  <Row align="center">
           <Spin size="large" />
         </Row>}
      </React.Fragment>


  );
}

export default (QSDashboardGeneric);
